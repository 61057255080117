import React from "react";
import ServiceCenterTable from "./ServiceCenterTable";

const ServiceCenters = () => {
  return (
    <div>
      <ServiceCenterTable />
    </div>
  );
};

export default ServiceCenters;
