import React, {useContext, useState} from "react";
import {Bar} from "react-chartjs-2";
import {Card, CardImg, CardText, CardBody, CardLink, CardTitle, CardSubtitle} from "reactstrap";
import {ServiceRequestContext} from "../../../contexts/ServiceRequestContext";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {FiRefreshCcw} from "react-icons/fi";
import {useServiceRequestsApiServices} from "../../../services/serviceRequestApiServices";
import {getFirstDayOfMonth, getFirstDayOfTheYear, getLastDayOfMonth, getLastDayOfTheYear, getTodayDate} from "../../../helpers/timeFormatHelper";

export const BarChartWidget = () => {
  const {getServiceRequestBreakdown} = useServiceRequestsApiServices();

  const [time, setTime] = useState("All");
  const [toggled, setToggled] = useState(false);
  const handleToggle = () => {
    setToggled(!toggled);
  };

  const {serviceRequestBreakdownByCenter} = useContext(ServiceRequestContext);

  const handleTimeChange = (time) => {
    setTime(time);
    if (time === "All") {
      getServiceRequestBreakdown({spinner: false, mode: "center", startDate: "", endDate: ""});
    } else if (time === "This year") {
      getServiceRequestBreakdown({spinner: false, mode: "center", startDate: getFirstDayOfTheYear(), endDate: getLastDayOfTheYear()});
    } else if (time === "This month") {
      getServiceRequestBreakdown({spinner: false, mode: "center", startDate: getFirstDayOfMonth(), endDate: getLastDayOfMonth()});
    } else {
      getServiceRequestBreakdown({spinner: false, mode: "center", startDate: getTodayDate(), endDate: getTodayDate()});
    }
  };

  const dataBar = {
    borderWidth: 0,
    // labels: ["Center A", "Center T", "Center Y", "Center M", "Center Q", "Center T", "Center B"],
    labels: serviceRequestBreakdownByCenter.map((item) => (item?._id.length > 10 ? item?._id.slice(0, 10) + ".." : item?._id)),
    datasets: [
      {
        label: "Service requests",
        backgroundColor: "rgb(36,199,144)",
        borderColor: "rgb(36,199,144)",
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 5,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        // minBarLength:1000,
        data: serviceRequestBreakdownByCenter.map((item) => item?.total),
      },
    ],
  };

  var options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    indexAxis: "y",
    scales: {
      x: {
        title: {
          color: "grey",
          display: true,
          text: "Service request",
        },
        grid: {
          display: false,
          borderColor: "white",
        },
      },
      y: {
        grid: {
          display: false,
          borderColor: "white",
        },
      },
    },
  };

  return (
    <div>
      <Card body>
        {/* style={{height: 350}} */}
        <CardTitle className=" justify-content-between">
          <div className="justify-content-between d-flex w-100">
            <span>Service requests</span>
            <Dropdown direction="top" className="d-inline" isOpen={toggled} toggle={handleToggle}>
              <span>
                <FiRefreshCcw className="me-1" color="grey" size={14} />
              </span>
              <DropdownToggle caret className="btn-sm p-0 text-primary px-1 border-white" style={{backgroundColor: "white"}}>
                {time}
              </DropdownToggle>
              <DropdownMenu>
                {time !== "This year" && <DropdownItem onClick={(e) => handleTimeChange("This year")}>This year</DropdownItem>}
                {time !== "This month" && <DropdownItem onClick={() => handleTimeChange("This month")}>This month</DropdownItem>}
                {time !== "Today" && <DropdownItem onClick={() => handleTimeChange("Today")}>Today</DropdownItem>}
                {time !== "All" && <DropdownItem onClick={() => handleTimeChange("All")}>All</DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          </div>
          {/* <span>Today</span> */}
        </CardTitle>
        <CardBody className="p-0">
          <Bar data={dataBar} options={options} width={200} height={150} />
        </CardBody>
      </Card>
    </div>
  );
};
