import axios from "axios";
import {useContext} from "react";
import {NotificationManager} from "react-notifications";
import {api} from "../constants/urls";
import {LoadingContext} from "../contexts/LoadingContext";
import {NotificationsContext} from "../contexts/NotificationsContext";

export const useNotificationsApiServices = () => {
  const {setGetLoading, setPostLoading} = useContext(LoadingContext);
  const {notifications, setNotifications} = useContext(NotificationsContext);

  const getNotifications = async ({spinner = false} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/service-requests/notifications`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setNotifications(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const socketNewNotification = (data) => {};

  return {
    getNotifications,
    socketNewNotification,
  };
};
