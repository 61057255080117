import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";
import { LoadingContext } from "../contexts/LoadingContext";
import { api } from "../constants/urls";
import { NotificationManager } from "react-notifications";
import { closeAddVehicleModal } from "../Pages/others/vehicleManagement/VehicleManagement";
import { VehicleServicesContext } from "../contexts/VehicleServicesContext";
import { closeVehicleServiceModal } from "../Pages/others/vehicleManagement/vehicleDetails/VehicleDetails";
import { useSearchParams } from "react-router-dom";

export const useVehicleServicesApiServices = () => {
  const [searchParams] = useSearchParams();
  const pageFromQuery = searchParams.get("page");
  const pageNumber = pageFromQuery === null ? 1 : parseInt(pageFromQuery, 10);

  const { vehicleServices, setVehicleServices, vehicleServicesCount, setvehicleServicesCount, vehicleService, setvehicleService, v } = useContext(VehicleServicesContext);
  const { setGetLoading, setPostLoading } = useContext(LoadingContext);

  const getVehicleServices = async ({ spinner = false, page = 1, oemVehicleId, serviceType, warranty } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/vehicles/services?item_per_page=20&page=${page}&oem_vehicle_id=${oemVehicleId}&service_type=${serviceType}&warranty=${warranty}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setVehicleServices(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getVehiclesServicesCount = async ({ oemVehicleId, serviceType, warranty } = {}) => {
    try {
      const res = await axios.get(`${api.oem}/v1.1/vehicles/services?oem_vehicle_id=${oemVehicleId}&service_type=${serviceType}&warranty=${warranty}&component=count`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        const count = res.data?.data?.total ? res.data?.data?.total : 0;
        setvehicleServicesCount(count);
      }
    } catch (err) {
      NotificationManager.error(err.response.data.message);
    }
  };
  const createVehicleServices = async ({ body, oemVehicleId, serviceType, warranty } = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/vehicles/services`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Vehicle service created successfully");
        closeVehicleServiceModal();
        getVehicleServices({ spinner: false, page: 1, oemVehicleId, serviceType, warranty });
        getVehiclesServicesCount({ oemVehicleId, serviceType, warranty });
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const updateVehicleService = async ({ body, serviceId, oemVehicleId, serviceType, warranty } = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.put(`${api.oem}/v1.1/vehicles/services/${serviceId}`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Vehicle service updated successfully");
        closeVehicleServiceModal();
        getVehicleServices({ spinner: false, page: pageNumber, oemVehicleId, serviceType, warranty });
        getVehiclesServicesCount({ oemVehicleId, serviceType, warranty });
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const deleteVehicleService = async ({ serviceId, oemVehicleId, serviceType, warranty } = {}) => {
    try {
      const res = await axios.delete(`${api.oem}/v1.1/vehicles/services/${serviceId}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Vehicle service deleted successfully");
        getVehicleServices({ spinner: false, page: pageNumber, oemVehicleId, serviceType, warranty });
        getVehiclesServicesCount({ oemVehicleId, serviceType, warranty });
      }
    } catch (err) {
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  // const getVehicleMileage= async ({ vehicleId} = {}) => {
  //   try {
  //     spinner && setGetLoading(true);
  //     const res = await axios.get(`${api.oem}/v1.1/vehicles/mileage`);
  //     if (res.data.status === "error") {
  //       NotificationManager.error(res.data.msg);
  //     } else {
  //       setVehicleServices(res.data.data);
  //     }
  //     setGetLoading(false);
  //   } catch (err) {
  //     setGetLoading(false);
  //     NotificationManager.error("Network error please try again or check your internet connection ");
  //   }
  // };



  return {
    createVehicleServices,
    getVehicleServices,
    getVehiclesServicesCount,
    updateVehicleService,
    deleteVehicleService,
  };
};
