import {useContext} from "react";
import axios from "axios";
import {LoadingContext} from "../contexts/LoadingContext";
import {api} from "../constants/urls";
import {NotificationManager} from "react-notifications";
import {ServiceCentersContext} from "../contexts/ServiceCentersContext";
import {closeServiceCenterModal} from "../Pages/others/serviceCenters/ServiceCenterTable";
import {useMessagingService} from "./messagingService";
import emailMessages from "../data/emailMessages";

export const useServiceCenterApiServices = () => {
  const {setGetLoading, setPostLoading} = useContext(LoadingContext);
  const {setServiceCenters, setServiceCentersCount, setServiceCenter} = useContext(ServiceCentersContext);
  const {sendMessage} = useMessagingService();
  const getServiceCenters = async ({spinner = false, page = 1, oemId} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/users/?item_per_page=20&page=${page}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setServiceCenters(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const getServiceCentersCount = async ({spinner = false} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/users/?component=count`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        const count = res.data?.data?.total ? res.data?.data?.total : 0;
        setServiceCentersCount(count);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const creatServiceCenters = async ({body} = {}) => {
    console.log(body);
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/users`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Service center created successfully");
        closeServiceCenterModal();
        await sendMessage({
          type: "generic",
          email: body.rep_email,
          name: body.rep_name,
          subject: "Service Center Login Details",
          message: emailMessages.serviceCenterMessage({}),
        });
        getServiceCenters({spinner: false, page: 1});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const updateServiceCenters = async ({body, centerId} = {}) => {
    console.log(body);
    try {
      setPostLoading(true);
      const res = await axios.put(`${api.oem}/v1.1/users/${centerId}`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Service center updated successfully");
        closeServiceCenterModal();
        getServiceCenters({spinner: false, page: 1});
        getServiceCentersCount();
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const deleteServiceCenters = async ({centerId, oemId} = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.delete(`${api.oem}/v1.1/users/${centerId}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Service center deleted successfully");
        closeServiceCenterModal();
        getServiceCenters({spinner: false, page: 1, oemId});
        getServiceCentersCount({oemId});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getServiceCenter = async ({spinner = false, centerID} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/users/${centerID}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setServiceCenter(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const uploadAgreement = async ({body, centerID} = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.fileUpload}/v1.1/files/agreement-upload/${centerID}`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Agreement uploaded successfully");
        getServiceCenter({spinner: false, centerID});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      console.log(err);
      console.log("ffffffffffffffffffferr");
      // NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  return {
    getServiceCenters,
    creatServiceCenters,
    getServiceCentersCount,
    updateServiceCenters,
    deleteServiceCenters,
    getServiceCenter,
    uploadAgreement,
  };
};
