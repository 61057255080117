import emptyImg from "../assets/img/empty.png";

const EmptyPage = ({ text, subText = "", buttons = "" }) => {
  return (
    // <div className="">
    <div className="d-flex align-items-center justify-content-center flex-column w-100 ">
      {/* <img src={emptyImg} height={"35%"} width={"35%"} /> */}
      <img src={emptyImg} height={"20%"} width={"20%"} />
      <p className="mt-1 fw-bold text-muted mb-0">{text}</p>
      <small className="text-muted">{subText}</small>
      <div className="mt-2">{buttons}</div>
    </div>
  );
};

export default EmptyPage;
