export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      localStorage.setItem("access_token", action.payload.token);
      localStorage.setItem("user_data", JSON.stringify(action.payload));
      window.location.replace("/");
      return action.payload;
    case "LOGOUT":
      localStorage.clear();
      return null;
    // case LOGIN_FAILED:
    //   //console.log("yes");
    //   return {
    //     ...state,
    //     isAuth: false,
    //     isError: true,
    //     data: action.payload,
    //   };
    default:
      return state;
  }
};
