import React, {useContext, useEffect} from "react";

import {useSearchParams} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import {useAuthApiServices} from "../../services/AuthApiServices";

const AdminToken = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("user_id");
  const data_mode = searchParams.get("data_mode");
  const {generateAdminToken} = useAuthApiServices();

  useEffect(() => {
    generateAdminToken({adminToken: token, user_id: userId, data_mode});
  }, []);

  // console.log(token);
  // console.log(userId);
  return <div className="h-75 fw-bold d-flex justify-content-center align-items-center">Please Wait . . . .</div>;
};

export default AdminToken;
