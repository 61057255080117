import React, {useContext, useEffect, useState} from "react";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText, Table} from "reactstrap";
import {useVehicleServicesApiServices} from "../../../../services/VehicleServicesApiService";
import Pagination from "react-js-pagination";
import {useHistory, useParams} from "react-router-dom";
import {formatWarantyType, getServiceType} from "../../../../helpers/stringsHelper";
import {VehicleServicesContext} from "../../../../contexts/VehicleServicesContext";
import EmptyPage from "../../../../components/EmptyPage";
import {useQueryStringHelper} from "../../../../helpers/queryStringHelper";
import {usePaginationHelper} from "../../../../helpers/paginationHelper";
import {VehicleContext} from "../../../../contexts/VehiclesContext";
import {deleteDialog} from "../../../../helpers/deleteDialogHelper";
import {RiEdit2Fill} from "react-icons/ri";
import {AiTwotoneDelete} from "react-icons/ai";

const VehicleServicesTable = ({serviceType, openVehicleServiceModal, buttonsComponent}) => {
  const {id} = useParams();
  const {getPageNoFromQueryString} = useQueryStringHelper();
  const {getVehicleServices, getVehiclesServicesCount} = useVehicleServicesApiServices();
  const {paginate} = usePaginationHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const {vehicleServices, vehicleServicesCount} = useContext(VehicleServicesContext);
  const {vehicle} = useContext(VehicleContext);
  const {deleteVehicleService} = useVehicleServicesApiServices();
  const [servcType, setServcType] = useState("");

  useEffect(() => {
    getVehicleServices({spinner: false, page: currentPage, oemVehicleId: id, serviceType, warranty: serviceType === "" ? "1" : "0"});
    getVehiclesServicesCount({oemVehicleId: id, serviceType, warranty: serviceType === "" ? "1" : "0"});
  }, []);

  const onPaginate = (pageNumber) => {
    paginate({pageNumber, setCurrentPage, callbackFunction: getVehicleServices({spinner: false, page: pageNumber, oemVehicleId: id, serviceType, warranty: serviceType === "" ? "1" : "0"})});
  };

  const onDelete = (serviceId) => {
    const asyncFunction = () => deleteVehicleService({serviceId, oemVehicleId: vehicle.vehicle_id, serviceType, warranty: serviceType === "" ? "1" : "0"});
    deleteDialog({Message: "Delete Vehicle Service", asyncFunction});
  };

  const filterType = (type) => {
    setServcType(type);
    getVehicleServices({spinner: false, page: currentPage, oemVehicleId: id, serviceType: type, warranty: "1"});
    getVehiclesServicesCount({oemVehicleId: id, serviceType: type, warranty: "1"});
  };

  return (
    <div>
      <Table borderless striped responsive className="text-nowrap">
        <thead>
          <tr>
            <th className="fw-normal">
              {serviceType !== "" && getServiceType(serviceType)}
              {serviceType === "" && (
                <select type="select" className="b-0 w-75" style={{border: 0, outline: 0}} value={servcType} onChange={(e) => filterType(e.target.value)}>
                  <option value="">All</option>
                  <option value="repair">Repairs</option>
                  <option value="maintenance">Maintenance</option>
                  <option value="defect">Defect</option>
                </select>
              )}
            </th>
            <th className="fw-normal">Name</th>
            <th className="fw-normal">Description</th>
            {serviceType !== "part" && <th className="fw-normal">TAT (hrs)</th>}
            {serviceType === "maintenance" && <th className="fw-normal">Mileage (miles)</th>}
            {serviceType === "maintenance" && <th className="fw-normal">Duration (months)</th>}
            {serviceType === "" && <th className="fw-normal">Warranty Type</th>}
            {serviceType === "" && <th className="fw-normal">Time Validity (years)</th>}
            {serviceType === "" && <th className="fw-normal">Frequency Validity</th>}
          </tr>
        </thead>
        {vehicleServices.length > 0 && (
          <tbody>
            {vehicleServices.map((item, index) => (
              <tr key={index}>
                <td>{item?.service_type}</td>
                <td>{item?.name}</td>
                <td>{item?.description}</td>
                {serviceType !== "part" && <td>{item?.tat}</td>}
                {serviceType === "maintenance" && <td>{item?.mileage ? item?.mileage?.toLocaleString() : "N/A"}</td>}
                {serviceType === "maintenance" && <td>{item?.maintenance_time ? item?.maintenance_time : "N/A"}</td>}
                {serviceType === "" && <td>{formatWarantyType(item?.warranty_type)}</td>}
                {serviceType === "" && <td>{item?.time_validity ? item?.time_validity : "N/A"}</td>}
                {serviceType === "" && <td>{item?.frequency_validity ? item?.frequency_validity : "N/A"}</td>}
                <td>
                  <span className="text-warning fw-bold cursor-pointer" onClick={() => openVehicleServiceModal(serviceType, item)}>
                    <RiEdit2Fill />
                  </span>
                  <span className="text-danger fw-bold cursor-pointer" onClick={() => onDelete(item.service_id)}>
                    <AiTwotoneDelete />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {vehicleServices.length > 0 && (
        <div>
          <Pagination activePage={currentPage} itemsCountPerPage={20} totalItemsCount={vehicleServicesCount} onChange={onPaginate} itemClass="page-item" linkClass="page-link" />
        </div>
      )}
      {vehicleServices.length === 0 && (
        <div className="mt-3">
          {/* <EmptyPage text={"FG"} /> */}
          <EmptyPage
            text={serviceType === "" ? "No repairs and spare parts covered by warranty" : serviceType === "maintenance" ? "No routine maintenance" : "No spare parts"}
            subText={serviceType === "" ? "Add repairs and spare parts covered by warranty here." : serviceType === "maintenance" ? "Add routine maintenance here." : "Add spare parts here."}
            buttons={buttonsComponent}
          />
        </div>
      )}
    </div>
  );
};
export default VehicleServicesTable;
