import {useContext, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import axios from "axios";
import {LoadingContext} from "../contexts/LoadingContext";
import {api} from "../constants/urls";
import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";
import {NotificationManager} from "react-notifications";
import {closePasswordChangeModal} from "../components/PagesTop";

export const useAuthApiServices = () => {
  const {auth, dispatch} = useContext(AuthContext);
  const {postLoading, setPostLoading} = useContext(LoadingContext);
  const loginUser = async ({email = "", password = ""}) => {
    const body = {email, password};
    try {
      setPostLoading(true);
      const res = await axios.post(`https://oem-service-microservices.api.lagosride.com/v1.1/auth/login`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        console.log(res.data);
        const userType = res.data.data.user_type;
        if (userType === "oem" || "center") {
          NotificationManager.success("Login Successful");
          await dispatch(AuthActionSuccess(res.data.data));
          window.location.replace("/");
        } else {
          NotificationManager.error("Invalid phone or password");
        }
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const initiatePasswordReset = async ({email = ""}) => {
    const body = {email};
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/auth/request-password-reset`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
        setPostLoading(false);
      } else {
        NotificationManager.success("Reset Instruction Sent");
        setPostLoading(false);
        return true;
      }
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const resetPassword = async ({token = "", password = ""}) => {
    const body = {token, password};
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/auth/reset-password`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Password Reset Successful");
        window.location.replace("/signin");
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const changePassword = async ({body}) => {
    console.log(body);
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/users/change-password`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Password Changed Successful");
        closePasswordChangeModal();
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const logoutUser = async () => {
    try {
      // setPostLoading(true);
      // const res = await axios.get(`${api.oem}/v1.1/auth/logout`);
      // if (res.data.status === "error") {
      //   NotificationManager.error(res.data.msg);
      // } else {
      //   await dispatch(LogoutAction());
      //   window.location.replace("/signin");
      // }
      // setPostLoading(false);
      localStorage.clear();
      window.location.replace("/signin");
    } catch (err) {
      console.log(err);
      setPostLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const generateAdminToken = async ({adminToken = "", user_id = "", data_mode}) => {
    const body = {user_id};
    axios.defaults.headers.common["Authorization"] = adminToken;
    try {
      setPostLoading(true);
      const res = await axios.post(`https://${data_mode === "test" ? "staging-server.lagosride.com/oem-service" : "oem-service-microservices.api.lagosride.com"}/v1.1/users/admin-token`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
        window.location.replace("/signin");
      } else {
        console.log(res.data);
        const userType = res.data.data.user_type;
        if (userType === "oem" || "center") {
          NotificationManager.success("Login Successful");
          await dispatch(AuthActionSuccess(res.data.data));
        } else {
          NotificationManager.error("Invalid phone or password");
        }
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  return {
    loginUser,
    logoutUser,
    initiatePasswordReset,
    resetPassword,
    changePassword,
    generateAdminToken,
  };
};
