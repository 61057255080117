import React, {useContext, useEffect} from "react";
import {Card, CardImg, CardText, CardBody, CardLink, CardTitle, CardSubtitle} from "reactstrap";
import {FaBell} from "react-icons/fa";
import EmptyPage from "../../../components/EmptyPage";
import {useNotificationsApiServices} from "../../../services/notificationsApiServices";
import {NotificationsContext} from "../../../contexts/NotificationsContext";
import {calculatePostDate} from "../../../helpers/timeFormatHelper";

const Notifications = ({maxHeight}) => {
  const {getNotifications} = useNotificationsApiServices();
  const {notifications} = useContext(NotificationsContext);
  useEffect(async () => {
    getNotifications();
  }, []);

  return (
    <Card body style={{maxHeight, overflowY: "scroll"}} className="notifications-card pt-0 px-0">
      <CardTitle className="mt-0 p-2 d-flex justify-content-between sticky-top mt-1 bg-light">
        <span>Notifications</span>
        <span>
          <FaBell color="#197088" size={25} />
        </span>
      </CardTitle>
      <CardText className="px-1">
        {notifications.map((item, index) => (
          <div
            className="mb-1 p-2 rounded"
            key={index}
            //  style={{backgroundColor: "#F8F9FA"}}
          >
            <small style={{fontSize: 15, lineHeight: 1}}>
              <span className="fw-bold cpitalize text-muted"> {`${item?.driver_name}`}</span> made a <span className="fw-bold text-muted">{item?.type}</span> service request
              <div className="text-end ">
                <i style={{fontSize: 12}}>{calculatePostDate(item?.createdAt)}</i>
              </div>
            </small>
          </div>
        ))}
      </CardText>
      {notifications.length < 1 && <div className="h-100 d-flex align-item-center justify-content-center">{/* <EmptyPage text="No Notifications" /> */} No Notifications</div>}
    </Card>
  );
};

export default Notifications;
