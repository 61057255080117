import React, {createContext, useState} from "react";

export const VehicleServicesContext = createContext();

const VehicleServicesContextProvider = ({children}) => {
  const [vehicleServices, setVehicleServices] = useState([]);
  const [vehicleServicesCount, setvehicleServicesCount] = useState(0);
  const [vehicleService, setvehicleService] = useState(0);

  return (
    <VehicleServicesContext.Provider value={{vehicleServices, setVehicleServices, vehicleServicesCount, setvehicleServicesCount, vehicleService, setvehicleService}}>
      {children}
    </VehicleServicesContext.Provider>
  );
};

export default VehicleServicesContextProvider;
