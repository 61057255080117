import React, {createContext, useState} from "react";

export const NotificationsContext = createContext();

const NotificationsContextProvider = ({children}) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationAlert, setNotificationAlert] = useState(false);

  return <NotificationsContext.Provider value={{notifications, setNotifications, notificationAlert, setNotificationAlert}}>{children} </NotificationsContext.Provider>;
};

export default NotificationsContextProvider;
