import { useContext } from "react";
import axios from "axios";
import { LoadingContext } from "../contexts/LoadingContext";
import { api } from "../constants/urls";
import { NotificationManager } from "react-notifications";
import { ServiceRequestContext } from "../contexts/ServiceRequestContext";
import { closeRejectionReasonModal, closeServiceCenterModal, closeWarrantyModal } from "../Pages/others/serviceRequests/ServiceRequestDetails";

export const useServiceRequestsApiServices = () => {
  const { setGetLoading, setPostLoading } = useContext(LoadingContext);
  const { setServiceRequests, setServiceRequestsCount, setServiceRequestBreakdownByType, setServiceRequestBreakdownByStatus, setServiceRequestBreakdownByCenter, setDefectReportCount, setServiceRequest } = useContext(ServiceRequestContext);

  const getServiceRequests = async ({ spinner = false, page = 1, serviceCenterId = "", status = "", reqType = "", vehicleId = "", startDate = "", endDate = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/service-requests/?service_center_id=${serviceCenterId}&status=${status}&vehicle_id=${vehicleId}&item_per_page=20&page=${page}&service_type=${reqType}&start_date=${startDate}&end_date=${endDate}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setServiceRequests(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getServiceRequestsCount = async ({ spinner = false, serviceCenterId = "", status = "", reqType = "", vehicleId = "", startDate = "", endDate = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/service-requests/?service_center_id=${serviceCenterId}&vehicle_id=${vehicleId}&status=${status}&service_type=${reqType}&component=count&start_date=${startDate}&end_date=${endDate}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        const count = res.data?.data?.total ? res.data?.data?.total : 0;
        setServiceRequestsCount(count);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const getServiceRequestsExport = async ({ spinner = false, serviceCenterId = "", status = "", reqType = "", vehicleId = "", startDate = "", endDate = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/service-requests/?service_center_id=${serviceCenterId}&vehicle_id=${vehicleId}&status=${status}&service_type=${reqType}&component=export&start_date=${startDate}&end_date=${endDate}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Data Exported Successfully");
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const getServiceRequestsByOem = async ({ spinner = false, page = 1, oemId, status = "", reqType = "", startDate = "", endDate = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/admin/service-requests/?oem_id=${oemId}&status=${status}&service_type=${reqType}&item_per_page=20&page=${page}&start_date=${startDate}&end_date=${endDate}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setServiceRequests(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const getServiceRequestsByOemCount = async ({ spinner = false, oemId, status = "", reqType = "", startDate = "", endDate = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/admin/service-requests/?oem_id=${oemId}&service_type=${reqType}&status=${status}&component=count&start_date=${startDate}&end_date=${endDate}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        const count = res.data?.data?.total ? res.data?.data?.total : 0;
        setServiceRequestsCount(count);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const getServiceRequestsByOemExport = async ({ spinner = false, oemId, status = "", reqType = "", startDate = "", endDate = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/admin/service-requests/?oem_id=${oemId}&service_type=${reqType}&status=${status}&component=export&start_date=${startDate}&end_date=${endDate}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Data Exported Successfully");
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const updateServiceRequests = async ({ requestId, body, pageNumber, serviceCenterId, beforeImages = "", afterImages = "", signature = "" } = {}) => {
    let reqBody = body;

    try {
      setPostLoading(true);
      if (body.status === "awaiting-approval" || body?.status === "ongoing") {
        //first upload images
        // const beforeImagesUrl = beforeImages !== "" ? await uploadFiles({ body: beforeImages }) : "";
        const beforeImagesUrl = beforeImages !== "" ? await uploadFiles({ body: beforeImages }) : "";
        // const afterImagesUrl = afterImages !== "" ? await uploadFiles({body: afterImages}) : "";
        // const signatureUrl = signature !== "" ? await uploadFiles({body: signature}) : "";
        reqBody = beforeImagesUrl ? { ...reqBody, before_images: [beforeImagesUrl] } : reqBody;
        // reqBody = afterImagesUrl ? {...reqBody, after_images: [afterImagesUrl]} : reqBody;
        // reqBody = signatureUrl ? {...reqBody, signature: signatureUrl} : reqBody;
      }
      if (body.status === "completed") {
        //first upload images
        // const beforeImagesUrl = beforeImages !== "" ? await uploadFiles({body: beforeImages}) : "";
        const afterImagesUrl = afterImages !== "" ? await uploadFiles({ body: afterImages }) : "";
        const signatureUrl = signature !== "" ? await uploadFiles({ body: signature }) : "";
        // reqBody = beforeImagesUrl ? {...reqBody, before_images: [beforeImagesUrl]} : reqBody;
        reqBody = afterImagesUrl ? { ...reqBody, after_images: [afterImagesUrl] } : reqBody;
        reqBody = signatureUrl ? { ...reqBody, signature: signatureUrl } : reqBody;
      }

      const res = await axios.put(`${api.oem}/v1.1/service-requests/${requestId}`, reqBody);

      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Service request updated successfully");
        closeRejectionReasonModal();
        // getServiceRequests({spinner: false, page: pageNumber, serviceCenterId});
        getServiceRequest({ requestId });
        getServiceRequestsCount({ spinner: false, serviceCenterId });
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getServiceRequest = async ({ spinner = false, requestId } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/service-requests/${requestId}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setServiceRequest(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getServiceRequestBreakdown = async ({ spinner = true, mode, startDate = "", endDate = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/service-requests/requests-breakdown/?mode=${mode}&start_date=${startDate}&end_date=${endDate}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        mode === "type" && setServiceRequestBreakdownByType(res.data.data);
        mode === "status" && setServiceRequestBreakdownByStatus(res.data.data);
        mode === "center" && setServiceRequestBreakdownByCenter(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getOemDefectReportCount = async ({ spinner = false, oemId, serviceType = "", status = "" } = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/admin/service-requests/?oem_id=${oemId}&service_type=${serviceType}&status=${status}&component=count`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        const count = res.data?.data?.total ? res.data?.data?.total : 0;
        setDefectReportCount(count);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const assignDeffectToCenter = async ({ requestId, serviceCenterId, comment } = {}) => {
    const body = {
      center_id: serviceCenterId,
      request_id: requestId,
      oem_comment: comment,
    };
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/service-requests/assign-defect`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Service request assigned successfully");
        closeServiceCenterModal();
        getServiceRequest({ requestId });
        getServiceRequestsCount({ spinner: false, serviceCenterId });
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const uploadFiles = async ({ body } = {}) => {
    try {
      const res = await axios.post(`${api.fileUpload}/v1.1/files/generic-picture`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        return res.data.url;
      }
    } catch (err) {
      NotificationManager.error("Network error while uploading image, please try again or check your internet connection ");
    }
  };

  const updateServiceRequestItems = async ({ requestId = "", isUnderWarranty = "" } = {}) => {
    const body = {
      request_id: requestId,
      is_under_warranty: isUnderWarranty,
    };
    try {
      setPostLoading(true);
      const res = await axios.put(`${api.oem}/v1.1/service-requests/update-request`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Updated successfully");
        closeWarrantyModal();
        getServiceRequest({ requestId });
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  return {
    getServiceRequests,
    getServiceRequestsCount,
    getServiceRequestsExport,
    updateServiceRequests,
    getServiceRequestsByOem,
    getServiceRequestsByOemCount,
    getServiceRequestsByOemExport,
    getServiceRequest,
    getServiceRequestBreakdown,
    getOemDefectReportCount,
    assignDeffectToCenter,
    updateServiceRequestItems,
  };
};
