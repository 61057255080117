import moment from "moment";

export const formatTime = (timeStamp) => {
  const fullDate = (() => moment(timeStamp).format("LL"))();
  const fullDateTime = (() => moment(timeStamp).format("MMMM Do YYYY, h:mm a"))();

  //   const timeAgo=()=>
  return {
    fullDate,
    fullDateTime,
  };
};

export const getFirstDayOfMonth = () => {
  const date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const month = date.getMonth().toString().length === 1 && date.getMonth().toString() !== "9" ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = firstDay.getDate().toString().length === 1 ? "0" + firstDay.getDate() : firstDay.getDate();
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getLastDayOfMonth = () => {
  const date = new Date();
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  const month = date.getMonth().toString().length === 1 && date.getMonth().toString() !== "9" ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = lastDay.getDate().toString().length === 1 ? "0" + lastDay.getDate() : lastDay.getDate();
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getTodayDate = () => {
  const date = new Date();
  const month = date.getMonth().toString().length === 1 && date.getMonth().toString() !== "9" ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getFirstDayOfTheYear = () => moment(new Date(new Date().getFullYear(), 0, 1)).format("YYYY-MM-DD");
export const getLastDayOfTheYear = () => moment(new Date(new Date().getFullYear(), 11, 31)).format("YYYY-MM-DD");

export const calculatePostDate = (timestamp) => {
  return moment(timestamp).fromNow();
};
