import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { FaAngleDown, FaEye } from "react-icons/fa";
import { GoChevronDown } from "react-icons/go";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Table } from "reactstrap";
import EmptyPage from "../../../components/EmptyPage";
import { AuthContext } from "../../../contexts/AuthContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { ServiceRequestContext } from "../../../contexts/ServiceRequestContext";
import { serviceRequestStatuses, serviceRequestType } from "../../../data/serviceRequestTypes";
import { getServiceRequestStatusColor } from "../../../helpers/badgeColorHelper";
import { usePaginationHelper } from "../../../helpers/paginationHelper";
import { useQueryStringHelper } from "../../../helpers/queryStringHelper";
import { formatTime, getTodayDate } from "../../../helpers/timeFormatHelper";
import { useServiceRequestsApiServices } from "../../../services/serviceRequestApiServices";
import { deleteDialog } from "../../../helpers/deleteDialogHelper";
const ServiceRequestTable = ({ justView }) => {
  const { getServiceRequests, getServiceRequestsCount, getServiceRequestsByOem, getServiceRequestsByOemCount, getServiceRequestsExport, getServiceRequestsByOemExport } = useServiceRequestsApiServices();
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const { getLoading } = useContext(LoadingContext);
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const { serviceRequests, serviceRequestsCount } = useContext(ServiceRequestContext);
  const { auth } = useContext(AuthContext);
  const { paginate } = usePaginationHelper();
  const [reqType, setReqType] = useState("");
  const [status, setStatus] = useState("");
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterStatus, setFilterStatus] = useState({
    startDate,
    endDate,
  });
  useEffect(() => {
    if (auth.user_type === "center") {
      getServiceRequests({
        serviceCenterId: auth?.auth_id,
        spinner: false,
        page: currentPage,
        reqType,
        status,
        startDate,
        endDate,
      });
      getServiceRequestsCount({
        serviceCenterId: auth?.auth_id,
        reqType,
        status,
        startDate,
        endDate,
      });
    } else {
      getServiceRequestsByOem({
        spinner: true,
        page: currentPage,
        oemId: auth?.auth_id,
        reqType,
        status,
        startDate,
        endDate,
      });
      getServiceRequestsByOemCount({
        spinner: false,
        oemId: auth?.auth_id,
        reqType,
        status,
        startDate,
        endDate,
      });
    }
  }, [reqType, status, filterStatus]);

  const onPaginate = (pageNumber) => {
    if (auth.user_type === "center") {
      paginate({
        pageNumber,
        setCurrentPage,
        callbackFunction: getServiceRequests({
          spinner: false,
          page: pageNumber,
          serviceCenterId: auth?.auth_id,
          startDate,
          endDate,
        }),
      });
    } else {
      paginate({
        pageNumber,
        setCurrentPage,
        callbackFunction: getServiceRequestsByOem({
          spinner: false,
          page: pageNumber,
          oemId: auth?.auth_id,
          startDate,
          endDate,
        }),
      });
    }
  };

  const onExport = () => {
    const asyncFunction = () =>
      auth.user_type === "center"
        ? getServiceRequestsExport({
            serviceCenterId: auth?.auth_id,
            reqType,
            status,
            startDate,
            endDate,
          })
        : getServiceRequestsByOemExport({
            spinner: false,
            oemId: auth?.auth_id,
            reqType,
            status,
            startDate,
            endDate,
          });
    deleteDialog({ Message: "Export Service Requests", asyncFunction });
  };
  const handleDateFilter = (e) => {
    e.preventDefault();
    setToggled((prev) => !prev);
    setFilterStatus({ startDate, endDate });
  };

  return (
    <div>
      {!getLoading && (
        <Card body style={{ minHeight: 500 }}>
          <div className="m-3 d-flex align-items-end">
            <div className="me-3">
              <Label for="start-date">
                From <Input className="mt-1" id="start-date" max={getTodayDate()} type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </Label>
            </div>
            <div className="me-3">
              <Label for="start-date">
                To
                <Input className="mt-1 me-2" id="end-date" type="date" max={getTodayDate()} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </Label>
            </div>
            <div className="btn btn-success bt-sm py-1 cursor-pointer me-2 mb-1" onClick={handleDateFilter}>
              filter
            </div>
            <div className="btn btn-success bt-sm py-1 cursor-pointer mb-1" onClick={onExport}>
              Export
            </div>
          </div>

          {/* <CardTitle className="">Service requests</CardTitle> */}
          <div className="col-12">
            <Table borderless striped responsive className="text-nowrap">
              <thead>
                <tr>
                  <th className="fw-normal">
                    <select type="select" className="b-0" style={{ border: 0, outline: 0 }} onChange={(e) => setReqType(e.target.value)}>
                      <option value="">Request type </option>
                      {serviceRequestType.map((item) => (
                        <option value={item.value} key={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th className="fw-normal">
                    {/* <Dropdown direction="bottom" className="d-inline" isOpen={toggled} toggle={() => setToggled((prev) => !prev)}>
                      <DropdownToggle className="btn-sm p-0 border-0 bg-none text-black" style={{ backgroundColor: "transparent" }}>
                        Date Created <GoChevronDown />
                      </DropdownToggle>
                      <DropdownMenu className="p-3">
                        <form onSubmit={handleDateFilter}>
                          <Label for="start-date">From</Label>
                          <Input className="mt-1" id="start-date" max={getTodayDate()} type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                          <Label for="start-date" className="mt-3">
                            To
                          </Label>
                          <Input className="mt-1 mb-2 " id="end-date" type="date" max={getTodayDate()} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                          <div className="d-flex justify-content-end">
                            <Button className="mt-2" size="sm" color="success">
                              Apply
                            </Button>
                          </div>
                        </form>
                      </DropdownMenu>
                    </Dropdown> */}
                    Date Created
                  </th>
                  <th className="fw-normal">Covered by warranty</th>
                  <th className="fw-normal">Driver name</th>
                  <th className="fw-normal">Plate number</th>
                  <th className="fw-normal">Urgency</th>
                  <select type="select" className="b-0 w-75" style={{ border: 0, outline: 0 }} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Status</option>
                    {serviceRequestStatuses.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* dates */}
                  {status === "accepted" && <th className="fw-normal">Inspection Availability Date</th>}
                  {status === "repair_time_set" && <th className="fw-normal">Operation Set Time</th>}
                  {status === "ongoing" && <th className="fw-normal">Operation Start Time</th>}
                  {status === "completed" && <th className="fw-normal">Completion Time</th>}
                  {/* dates end */}

                  <th className="fw-normal">Action </th>
                </tr>
              </thead>
              <tbody>
                {serviceRequests.length > 0 &&
                  serviceRequests.map((item, index) => (
                    <tr key={index}>
                      <td className="capitlize">{item?.service_type}</td>
                      <td className="capitlize">{formatTime(item?.createdAt).fullDateTime}</td>
                      <td>
                        <span class={`capitlize badge bg-${item?.covered_by_warranty ? "success" : "danger"}`}>{item?.covered_by_warranty ? "Yes" : "No"}</span>
                      </td>

                      <td>{item?.driver_name}</td>
                      <td>{item?.plate_number}</td>
                      <td>
                        <span className={`${item?.urgency === "high" ? "text-danger" : "text-warning"} fw-bold capitlize`}>{item?.urgency}</span>
                        {/* {(item?.service_type === "maintenance" || item?.service_type === "defect") && <span className="fw-bold">N/A</span>} */}
                        {item?.service_type === "maintenance" && <span className="fw-bold">N/A</span>}
                      </td>

                      <td>
                        <span class={` capitlize badge bg-${getServiceRequestStatusColor(item?.status)}`} style={{ minWidth: 80 }}>
                          {item?.status === "awaiting-approval" ? "Awaiting Approval" : item?.status === "repair_time_set" ? "Operation Time Set" : item?.status}{" "}
                        </span>
                      </td>
                      {/* dates */}

                      {status === "accepted" && <td className="fw-normal">{formatTime(item?.availabily_date).fullDateTime}</td>}
                      {status === "repair_time_set" && <td className="fw-normal">{formatTime(item?.repair_date).fullDateTime}</td>}
                      {status === "ongoing" && <td className="fw-normal">{formatTime(item?.start_time).fullDateTime}</td>}
                      {status === "completed" && <td className="fw-normal">{formatTime(item?.completion_time).fullDateTime}</td>}
                      {/* dates end */}
                      <td>
                        <Link to={`/service-requests/${item?._id}`} state={{ currItem: item, currentPage, justView }}>
                          <span className="text-teal fw-bold">
                            <FaEye />
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                {serviceRequests.length < 1 && <EmptyPage text="No service request" />}
              </tbody>
            </Table>
          </div>
          <div>{serviceRequests.length > 0 && <Pagination activePage={currentPage} itemsCountPerPage={20} totalItemsCount={serviceRequestsCount} onChange={onPaginate} itemClass="page-item" linkClass="page-link" />}</div>
          <div></div>
        </Card>
      )}
    </div>
  );
};
export default ServiceRequestTable;
