export const getServiceRequestStatusColor = (status) => {
  if (status === "pending") {
    return "secondary";
  } else if (status === "accepted") {
    return "warning";
  } else if (status === "ongoing") {
    return "info";
  } else if (status === "completed") {
    return "success";
  } else {
    return "danger";
  }
};
