import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody} from "reactstrap";
import {MdOutlineCarRepair} from "react-icons/md";
import {GiAutoRepair} from "react-icons/gi";
import {AiFillCar} from "react-icons/ai";
import Notifications from "./Notifications";
import DougnutWidget from "./DougnutWidget";
import {BarChartWidget} from "./BarChartWidget";
import ServiceRequestTable from "../shared/ServiceRequestTable";
import WorkOrderTable from "../shared/WorkOrderTable";
import {useServiceRequestsApiServices} from "../../../services/serviceRequestApiServices";
import {LoadingContext} from "../../../contexts/LoadingContext";
import {ServiceRequestContext} from "../../../contexts/ServiceRequestContext";

const OverviewServiceCenter = () => {
  const {getLoading} = useContext(LoadingContext);
  const {getServiceRequestBreakdown} = useServiceRequestsApiServices();

  useEffect(async () => {
    await getServiceRequestBreakdown({spinner: true, mode: "type"});
    await getServiceRequestBreakdown({spinner: true, mode: "status"});
    await getServiceRequestBreakdown({spinner: true, mode: "center"});
  }, []);

  const [time, setTime] = useState("All");

  const handleTimeChange = (time) => {
    setTime(time);
  };

  const {serviceRequestBreakdownByStatus} = useContext(ServiceRequestContext);

  const findValue = (status) => (serviceRequestBreakdownByStatus.find((item) => item?._id === status) ? serviceRequestBreakdownByStatus.find((item) => item?._id === status).total : 0);

  return (
    <div>
      {!getLoading && (
        <>
          <div className="row">
            <div className="col-12 col-lg-4 mt-3">
              <div className="row">
                <div className="col-12 ">
                  <Card className="">
                    <CardBody>
                      <div className="d-flex align-items-start">
                        <div className="me-3 rounded p-2 " style={{backgroundColor: "#FDF7F0"}}>
                          <MdOutlineCarRepair size={25} color="#E38100" />
                        </div>
                        <div>
                          <h2 style={{color: "#E38100"}} className="mb-0">
                            {findValue("pending")}
                          </h2>
                          <div className="fw-bold text-muted" style={{fontSize: 10}}>
                            Pending requests
                          </div>
                        </div>
                      </div>
                      {/* xsf */}
                    </CardBody>
                  </Card>
                </div>
                <div className="col-12 mt-5 py-2">
                  <Card className="">
                    <CardBody>
                      <div className="d-flex align-items-start">
                        <div className="me-3 rounded p-2 " style={{backgroundColor: "#EFFBF7"}}>
                          <GiAutoRepair size={25} color="#24C790" />
                        </div>
                        <div>
                          <h2 style={{color: "#24C790"}} className="mb-0">
                            {findValue("pending") + findValue("accepted") + findValue("ongoing") + findValue("completed") + findValue("rejected")}
                          </h2>
                          <div className="fw-bold text-muted" style={{fontSize: 10}}>
                            Total Service Requests
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-12 mt-5">
                  <Card className="">
                    <CardBody>
                      <div className="d-flex align-items-start">
                        <div className="me-3 rounded p-2 " style={{backgroundColor: "#EAF2F9"}}>
                          <AiFillCar size={25} color="#006AB5" />
                        </div>
                        <div>
                          <h2 style={{color: "#006AB5"}} className="mb-0">
                            {findValue("ongoing")}
                          </h2>
                          <div className="fw-bold text-muted" style={{fontSize: 10}}>
                            Ongoing Service Requests
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mt-3">
              <DougnutWidget cutout={85} />
              {/* maxHeight={374} */}
              {/* maxHeight={374} */}
            </div>
            <div className="col-12 col-lg-4 mt-3">
              <Notifications maxHeight={380}/>
              {/* maxHeight={374}  */}
            </div>
          </div>
          <div className="mt-3">
            {/* <WorkOrderTable /> */}
            <ServiceRequestTable />
          </div>
        </>
      )}
    </div>
  );
};

export default OverviewServiceCenter;
