import React, {useContext, useEffect, useState} from "react";
import {Card, CardImg, CardText, CardBody, CardLink, CardTitle, CardSubtitle} from "reactstrap";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText} from "reactstrap";
import {Link} from "react-router-dom";
import {useVehicleApiServices} from "../../../services/vehicleApiService";
import {AuthContext} from "../../../contexts/AuthContext";
import {VehicleContext} from "../../../contexts/VehiclesContext";
import {LoadingContext} from "../../../contexts/LoadingContext";
import EmptyPage from "../../../components/EmptyPage";
import {AiFillEdit, AiFillDelete} from "react-icons/ai";
import placeholderImage from "../../../assets/img/vehicle-placeholder.png";
import {formatTime} from "../../../helpers/timeFormatHelper";
import {useNavigate} from "react-router-dom";
import {MdOutlineArrowForwardIos} from "react-icons/md";
export let closeAddVehicleModal;
const VehicleManagement = () => {
  const {vehicles, vehiclesCount} = useContext(VehicleContext);
  const {getLoading} = useContext(LoadingContext);
  const {getVehicles, createVehicle, updateVehicle, deleteVehicle, getVehiclesCount} = useVehicleApiServices();
  const {auth} = useContext(AuthContext);
  const [addVehicleModal, setAddVehicleModal] = useState(false);
  const [formData, setFormData] = useState({brandName: "", model: "", year: "", image: ""});
  const [editedId, setEditedId] = useState("");
  const [vehiclePhoto, setVehiclePhoto] = useState("");

  const [instructionModal, setInstructionModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    await getVehicles({spinner: true, page: 1, oemId: auth?.auth_id});
    await getVehiclesCount({spinner: true, oemId: auth?.auth_id});
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading && vehiclesCount < 1) {
      setInstructionModal(true);
    }
  }, []);

  closeAddVehicleModal = () => {
    setAddVehicleModal(false);
    setFormData({brandName: "", model: "", year: "", image: ""});
    setEditedId("");
  };
  const openAddVehicleModal = () => {
    setAddVehicleModal(true);
  };

  const closeInstructionModal = () => {
    setInstructionModal(false);
    setAddVehicleModal(true);
  };

  let navigate = useNavigate();

  const editVehicle = (vehicleId) => {
    setEditedId(vehicleId);
    vehicles.map((item) => {
      if (item.vehicle_id === vehicleId)
        setFormData({
          ...formData,
          brandName: item?.brand_name,
          model: item?.model,
          year: item?.year,
          // image: item?.image,
        });
    });
    openAddVehicleModal();
  };
  const vehicleDelete = (id) => {
    deleteVehicle({vehicleID: id, oemId: auth?.auth_id});
  };

  const cardClick = (e, id) => {
    if (e.target.id === "carName") {
      editVehicle(id);
    } else {
      navigate(`/vehicle-management/${id}`, {replace: true});
    }
  };

  const imageChange = (e) => {
    setVehiclePhoto(e.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const imageBody = new FormData();
    imageBody.append("photo", vehiclePhoto);
    const vehicleDTO = {
      brand_name: formData?.brandName,
      model: formData?.model,
      year: parseInt(formData?.year, 10),
    };
    !editedId
      ? createVehicle({body: vehicleDTO, oemId: auth?.auth_id, vehiclePhoto: imageBody})
      : updateVehicle({body: vehicleDTO, oemId: auth?.auth_id, vehicleID: editedId, vehiclePhoto: imageBody});
  };
  const onChange = (e) => {
    e.target.name !== "image" ? setFormData({...formData, [e.target.name]: e.target.value}) : setFormData({...formData, [e.target.name]: e.target.files});
  };

  return (
    <div>
      <div className="text-end mb-3">
        {!getLoading && (
          <Button className="btn-modal-accept" onClick={openAddVehicleModal}>
            Add Vehicle Model
          </Button>
        )}
      </div>
      <div className="row">
        {!getLoading &&
          vehicles.length > 0 &&
          vehicles.map((item, index) => (
            <div className="col-6 col-md-4 col-lg-3 mb-3" key={index}>
              <Card body style={{height: 300}} className="p-2" onClick={(e) => cardClick(e, item.vehicle_id)}>
                <div className="rounded">
                  {item.image && <img className="rounded" src={item.image} width={"100%"} height={160} alt="vehicleImage" />}
                  {!item.image && <img className="rounded " src={placeholderImage} width={"100%"} height={160} alt="vehicleImage" />}
                </div>
                <small style={{color: "#2E407B"}} id="carName" className="fw-bold cursor-pointer">
                  {`${item.brand_name} ${item.model}  ${item.year}`}
                </small>
                <div className="" style={{marginTop: 70, zIndex: 20}}>
                  <small className="text-muted">
                    <i>Added: {formatTime(item?.createdAt).fullDate}</i>
                  </small>
                </div>
                <div className="mt-4">
                  {/* <small>
                      <span className="me-2 text-white badge bg-success">
                        <span className="d-flex align-items-center cursor-pointer">
                          <AiFillEdit color="white" />
                          <span className="ms-1">Edit</span>
                        </span>
                      </span>
                    </small> */}
                </div>
              </Card>
              {/* </Link> */}
            </div>
          ))}
      </div>
      <div>{!getLoading && vehicles.length === 0 && <EmptyPage text="No Vehicles " />}</div>

      <Modal isOpen={instructionModal} toggle={closeInstructionModal} size="md" scrollable backdrop="static">
        <ModalHeader className="fw-2 text-teal p-4 pb-1" toggle={closeInstructionModal}>
          <h5 className="fw-bolder">Add Vehicle Model Details</h5>
        </ModalHeader>
        <ModalBody style={{height: 150}} className="p-4">
          Here you are required to Provide us with the model of cars deployed to LagosRide
          <div className="mt-4 d-flex justify-content-end">
            <Button className="btn-modal-accept p-2" onClick={closeInstructionModal}>
              <div className="d-flex">
                <span>Add Model</span>
                <span className="ms-2">
                  <MdOutlineArrowForwardIos />
                </span>
              </div>
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={addVehicleModal} toggle={closeAddVehicleModal} size="sm" scrollable backdrop="static">
        <ModalHeader toggle={closeAddVehicleModal}>
          <div className="d-flex justify-content-between">
            <div>{editedId ? "Edit" : "Add"} vehicle Model</div>
          </div>
        </ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody style={{height: 250}}>
            <div className="mt-2 w-100">
              <FormGroup>
                <Label for="brand-name">Brand name</Label>
                <Input id="brand-name" name="brandName" type="text" onChange={onChange} value={formData?.brandName} required />
              </FormGroup>
              <div className="d-flex justify-content-between mt-3">
                <FormGroup className="me-2">
                  <Label for="model">Model</Label>
                  <Input id="model" name="model" type="text" onChange={onChange} value={formData?.model} required />
                </FormGroup>
                <FormGroup className="ms-2">
                  <Label for="year">Year</Label>
                  <Input id="year" name="year" type="text" onChange={onChange} value={formData?.year} required />
                </FormGroup>
              </div>
              <div className="mt-3">
                <FormGroup>
                  <Label for="image">Vehicle image</Label>
                  <div className="mt-2">
                    <input type="file" id="upload" onChange={imageChange} accept=".jpg, .jpeg, .png" style={{width: "90%"}} required={editedId === ""} />
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-modal-accept" type="submit">
              Submit
            </Button>
            <Button className="btn-modal-cancel" onClick={closeAddVehicleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default VehicleManagement;
