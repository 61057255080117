import React, { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { ServiceRequestContext } from "../../../contexts/ServiceRequestContext";
import { getFirstDayOfMonth, getFirstDayOfTheYear, getLastDayOfMonth, getLastDayOfTheYear, getTodayDate } from "../../../helpers/timeFormatHelper";
import { useServiceRequestsApiServices } from "../../../services/serviceRequestApiServices";

const DougnutWidget = ({maxHeight, cutout}) => {
  const {getLoading} = useContext(LoadingContext);
  const {getServiceRequestBreakdown} = useServiceRequestsApiServices();
  const findValue = (status) => (serviceRequestBreakdownByStatus.find((item) => item?._id === status) ? serviceRequestBreakdownByStatus.find((item) => item?._id === status).total : 0);
  const [values, setValues] = useState({pending: 0, accepted: 0, ongoing: 0, completed: 0, rejected: 0});
  const {serviceRequestBreakdownByStatus} = useContext(ServiceRequestContext);
  useEffect(() => {
    setValues({pending: findValue("pending"), ongoing: findValue("ongoing"), completed: findValue("completed"), accepted: findValue("accepted"), rejected: findValue("rejected")});
  }, [serviceRequestBreakdownByStatus]);

  const [toggled, setToggled] = useState(false);
  const handleToggle = () => {
    setToggled(!toggled);
  };
  const [time, setTime] = useState("All");

  const handleTimeChange = (time) => {
    setTime(time);
    if (time === "All") {
      getServiceRequestBreakdown({spinner: false, mode: "status", startDate: "", endDate: ""});
    } else if (time === "This year") {
      getServiceRequestBreakdown({spinner: false, mode: "status", startDate: getFirstDayOfTheYear(), endDate: getLastDayOfTheYear()});
    } else if (time === "This month") {
      getServiceRequestBreakdown({spinner: false, mode: "status", startDate: getFirstDayOfMonth(), endDate: getLastDayOfMonth()});
    } else {
      getServiceRequestBreakdown({spinner: false, mode: "status", startDate: getTodayDate(), endDate: getTodayDate()});
    }
  };
  // await getServiceRequestBreakdown({spinner: true, mode: "center"});
  const labels = [
    {
      title: "Pending",
      color: "grey",
    },
    {
      title: "Accepted",
      color: "rgb(8,110,185)",
    },
    {
      title: "Ongoing",
      color: "rgb(252,185,11)",
    },
    {
      title: "Completed",
      color: "rgb(41,198,149)",
    },
    {
      title: "Rejected",
      color: "red",
    },
  ];

  const total = values?.pending + values?.accepted + values?.ongoing + values?.completed + values?.rejected;

  // const plugins = [
  //   {
  //     beforeDraw: function (chart) {
  //       var width = chart.width,
  //         height = chart.height,
  //         ctx = chart.ctx;
  //       ctx.restore();
  //       var fontSize = (height / 160).toFixed(2);
  //       ctx.font = fontSize + "em sans-serif";
  //       ctx.textBaseline = "top";
  //       // var text = `1460`,
  //       var text = `${values?.pending + values?.accepted + values?.ongoing + values?.completed + values?.rejected}`,
  //         // var text = `${total}`,
  //         textX = Math.round((width - ctx.measureText(text).width) / 2),
  //         // textY = height / 2.3;
  //         textY = height / 3.5;
  //       ctx.fillText(text, textX, textY);
  //       var text2 = "Total",
  //         text2X = Math.round((width - ctx.measureText(text).width) / 2.3),
  //         // text2Y = height / 1.9;
  //         text2Y = height / 2.6;
  //       ctx.fillText(text2, text2X, text2Y);
  //       ctx.save();
  //     },
  //   },
  // ];
  const data = {
    labels: ["Pending", "Accepted", "Ongoing", "Completed", "Rejected"],

    datasets: [
      {
        label: "Attendance for Week 1",
        clip: 15,
        data: [values.pending, values.accepted, values.ongoing, values.completed, values.rejected],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["grey", "rgb(8,110,185)", "rgb(252,185,11)", "rgb(41,198,149)", "red"],
        boederColor: ["grey", "rgb(8,110,185)", "rgb(252,185,11)", "rgb(41,198,149)", "red"],
        pointBackgroundColor: "rgba(255,206,86,0.2)",
      },
    ],
    text: "83%",
  };

  // const option = {
  //   maintainAspectRatio: false,
  //   responsive: false,
  //   legend: {
  //   position: 'left',
  //   labels: {
  //   boxWidth: 10
  //   }
  //  }
  // }
  const options = {
    cutout,
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
        },
        display: true,
        position: "bottom",
        align: "center",
      },
      title: {
        display: false,
        text: "Doughnut Chart",
        color: "blue",
        font: {
          size: 50,
        },
        padding: {
          top: 30,
          bottom: 30,
        },
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
    },
  };

  return (
    <Card body>
      <CardTitle className=" justify-content-between">
        <div className="justify-content-between d-flex w-100">
          <span>Service requests</span>
          <Dropdown direction="top" className="d-inline" isOpen={toggled} toggle={handleToggle}>
            <DropdownToggle caret className="btn-sm p-0 text-primary px-2 border-white" style={{backgroundColor: "#E5F0F8"}}>
              {time}
            </DropdownToggle>
            <DropdownMenu>
              {time !== "This year" && <DropdownItem onClick={(e) => handleTimeChange("This year")}>This year</DropdownItem>}
              {time !== "This month" && <DropdownItem onClick={() => handleTimeChange("This month")}>This month</DropdownItem>}
              {time !== "Today" && <DropdownItem onClick={() => handleTimeChange("Today")}>Today</DropdownItem>}
              {time !== "All" && <DropdownItem onClick={() => handleTimeChange("All")}>All</DropdownItem>}
            </DropdownMenu>
          </Dropdown>
        </div>
        {/* <span>Today</span> */}
      </CardTitle>
      <CardBody className="my-3 p-2">
        <div style={{width: "250px", height: "250px", position: "relative"}} className="mx-auto">
          {/* <Doughnut data={data} options={options} width={200} height={200} /> */}
          <Doughnut data={data} options={options} width={250} height={250} />
          <div style={{position: "absolute", width: "100%", top: "40%", left: 0, textAlign: "center", marginTop: "-28px", lineHeight: "20px"}}>
            <h2 className="mb-0">{total}</h2>
            <span>Total</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DougnutWidget;
