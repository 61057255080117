import "../../assets/scss/auth.scss";


const AuthLayout = ({children, title}) => {
  return (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <div className="text-center">
        <div className="main-content">
          <div className="title">{title}</div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}; 
export default AuthLayout;
