import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody} from "reactstrap";
import {FaTools} from "react-icons/fa";
import {AiFillCar} from "react-icons/ai";
import Notifications from "./Notifications";
import DougnutWidget from "./DougnutWidget";
import {BarChartWidget} from "./BarChartWidget";
import ServiceRequestTable from "../shared/ServiceRequestTable";
import {LoadingContext} from "../../../contexts/LoadingContext";
import {useVehicleApiServices} from "../../../services/vehicleApiService";
import {AuthContext} from "../../../contexts/AuthContext";
import {VehicleContext} from "../../../contexts/VehiclesContext";
import {useServiceCenterApiServices} from "../../../services/serviceCentersApiServices";
import {ServiceCentersContext} from "../../../contexts/ServiceCentersContext";
import {useServiceRequestsApiServices} from "../../../services/serviceRequestApiServices";
import {ServiceRequestContext} from "../../../contexts/ServiceRequestContext";
import {useNavigate} from "react-router-dom";

const OverviewOem = () => {
  const {getLoading, setGetLoading} = useContext(LoadingContext);
  const {vehiclesCount} = useContext(VehicleContext);
  const {getVehiclesCount} = useVehicleApiServices();
  const {getServiceCentersCount} = useServiceCenterApiServices();
  const {serviceCentersCount} = useContext(ServiceCentersContext);
  const {getServiceRequestBreakdown, getOemDefectReportCount} = useServiceRequestsApiServices();
  const {defectReportCount} = useContext(ServiceRequestContext);

  const {auth} = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    await getVehiclesCount({spinner: true, oemId: auth?.auth_id});
    await getServiceCentersCount({spinner: false});
    await getOemDefectReportCount({spinner: false, oemId: auth?.auth_id, serviceType: "defect"});
    await getServiceRequestBreakdown({spinner: false, mode: "type"});
    await getServiceRequestBreakdown({spinner: false, mode: "status"});
    await getServiceRequestBreakdown({spinner: false, mode: "center"});
    setLoading(false);
  }, []);

  if (!loading && vehiclesCount < 1) {
    navigate("/vehicle-management");
  }

  // console.log(auth?.auth_id);

  return (
    <div>
      {!getLoading && (
        <div className="row d-flex">
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-12 col-md-4 mt-3">
                <Card className="">
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="me-3 rounded p-2 " style={{backgroundColor: "#FDF7F0"}}>
                        <FaTools size={25} color="#E38100" />
                      </div>
                      <div>
                        <h2 style={{color: "#E38100"}} className="mb-0">
                          {defectReportCount}
                        </h2>
                        <div className="fw-bold text-muted" style={{fontSize: 10}}>
                          Total defects reports
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <Card className="">
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="me-3 rounded p-2 " style={{backgroundColor: "#EFFBF7"}}>
                        <FaTools size={25} color="#24C790" />
                      </div>
                      <div>
                        <h2 style={{color: "#24C790"}} className="mb-0">
                          {serviceCentersCount}
                        </h2>
                        <div className="fw-bold text-muted" style={{fontSize: 10}}>
                          Total centers
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <Card className="">
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="me-3 rounded p-2 " style={{backgroundColor: "#EAF2F9"}}>
                        <AiFillCar size={25} color="#006AB5" />
                      </div>
                      <div>
                        <h2 style={{color: "#006AB5"}} className="mb-0">
                          {vehiclesCount}
                        </h2>
                        <div className="fw-bold text-muted" style={{fontSize: 10}}>
                          Total Vehicles
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="row ">
              <div className="col-12 col-md-5 mt-3 ">
                {/* maxHeight={400} */}
                <DougnutWidget cutout={80} />
              </div>
              <div className="col-12 col-md-7 mt-3 ">
                <BarChartWidget />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 mt-3 ">
            {/* maxHeight={455} */}
            <Notifications maxHeight={460} />
          </div>
          <div className="mt-3">{/* <ServiceRequestTable /> */}</div>
        </div>
      )}
    </div>
  );
};

export default OverviewOem;
