import React, {useContext, useState} from "react";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText, Table} from "reactstrap";
import {VehicleServicesContext} from "../../../../contexts/VehicleServicesContext";
import VehicleServicestable from "../vehicleServices/VehicleServicestable";
const MaintenanceTab = ({openVehicleServiceModal, serviceType}) => {
  const {vehicleServices, vehicleServicesCount} = useContext(VehicleServicesContext);
  const button = (
    <div className="fw-bold d-flex justify-content-end">
      <Button className="btn-modal-accept me-2" onClick={() => openVehicleServiceModal("maintenance")}>
        Add Routine Maintenance
      </Button>
    </div>
  );

  return (
    <div>
      <div>
        <div className="fw-bold text-muted mt-2">MAINTENANCE COVERED</div>
        {vehicleServicesCount > 0 && button}
        <VehicleServicestable serviceType={serviceType} openVehicleServiceModal={openVehicleServiceModal} buttonsComponent={button} />
      </div>
    </div>
  );
};

export default MaintenanceTab;
