import React, { useContext, useEffect, useState } from "react";
// import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { FcCancel } from "react-icons/fc";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AuthContext } from "../../../contexts/AuthContext";
import { ServiceRequestContext } from "../../../contexts/ServiceRequestContext";
import { deleteDialog } from "../../../helpers/deleteDialogHelper";
import { formatTime } from "../../../helpers/timeFormatHelper";
import { useServiceRequestsApiServices } from "../../../services/serviceRequestApiServices";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import moment from "moment";

import axios from "axios";
import { NotificationManager } from "react-notifications";
import { api } from "../../../constants/urls";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { ServiceCentersContext } from "../../../contexts/ServiceCentersContext";
import { useServiceCenterApiServices } from "../../../services/serviceCentersApiServices";
import Quotation from "./quotation";
export let closeRejectionReasonModal;
export let closeServiceCenterModal;
export let closeWarrantyModal;

const ServiceRequestDetails = () => {
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const { serviceRequest } = useContext(ServiceRequestContext);
  const { postLoading } = useState(true);
  const [vehicleMileage, setVehicleMileage] = useState("");
  const [dishchargeFormData, setDischargeFormData] = useState({
    diagnosticActivity: "",
    specificActivity: "",
    beforeImages: "",
    afterImages: "",
    name: "",
    signature: "",
    recommendation: "",
    quotations: Array.from({ length: 1 }).map((item) => ({
      item: "",
      description: "",
      quantity: "",
      amount: "",
    })),
  });

  const { diagnosticActivity, specificActivity, beforeImages, afterImages, name, signature, recommendation, quotations } = dishchargeFormData;

  const onDeleteFormRow = (id) => {
    // zero indexed
    if (quotations.length > 1) {
      const modifiedRows = quotations.filter((x, index) => index !== id);
      setDischargeFormData({ ...dishchargeFormData, quotations: modifiedRows });
    }
  };

  const showImages = (images) => {
    setImageSrc(images);
    setImageModal(true);
  };

  useEffect(async () => {
    await getServiceRequest({ spinner: true, requestId: id });
    if (auth?.user_type === "oem") {
      getServiceCenters({ spinner: true, page: 1, oemId: auth?.auth_id });
    }
    getVehicleMileage();
  }, []);

  const viewedDetail = serviceRequest;
  const location = useLocation();
  const { currItem, currentPage, justView, maintenanceHistory } = location?.state;

  async function getVehicleMileage() {
    try {
      const res = await axios.get(`${api.oem}/v1.1/vehicles/mileage/?vehicle_id=${currItem?.vehicle_id}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setVehicleMileage(res?.data?.data?.total_mileage);
      }
    } catch (err) {}
  }

  const [items, setItems] = useState(Array.from({ length: 6 }));
  const { getServiceRequest, updateServiceRequests, assignDeffectToCenter, updateServiceRequestItems } = useServiceRequestsApiServices();
  const { getServiceCenters } = useServiceCenterApiServices();
  const { serviceCenters } = useContext(ServiceCentersContext);
  const { getLoading } = useContext(LoadingContext);
  const [requestDetailModalOpen, setRequestDetailModalOpen] = useState(false);
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [warrantyModal, setWarrantyModal] = useState(false);
  const [warrantyStatus, setWarrantyStatus] = useState(false);
  const [repairTimeModal, setRepairTimeModal] = useState(false);
  const [rejectionReasonModal, setRejectionReasonModal] = useState(false);
  const [serviceCenterModal, setServiceCenterModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [availability, setAvailability] = useState(new Date());
  const [repairTime, setRepairTime] = useState(new Date());

  const [imageModal, setImageModal] = useState(null);
  const [imageSrc, setImageSrc] = useState("");

  const [serviceCenterId, setServiceCenterId] = useState(viewedDetail?.service_center_id);
  const [comment, setComment] = useState("");
  const openAvailabilityModal = () => {
    setAvailabilityModal(true);
  };
  const openRepairTimeModal = () => {
    setRepairTimeModal(true);
  };

  const closeAvailabilityModal = () => {
    setAvailabilityModal(false);
    setAvailability(new Date());
  };
  closeWarrantyModal = () => {
    setWarrantyModal(false);
    setWarrantyStatus("");
  };
  const closeRepairTimeModal = () => {
    setRepairTimeModal(false);
    setRepairTime(new Date());
  };

  closeRejectionReasonModal = () => {
    setRejectionReasonModal(false);
  };
  const openRejectionReasonModal = () => {
    setRejectionReasonModal(true);
  };

  closeServiceCenterModal = () => {
    setServiceCenterModal(false);
    setServiceCenterId(serviceCenterId);
  };

  const warrantUpdate = (e) => {
    e.preventDefault();
    updateServiceRequestItems({
      requestId: viewedDetail?.request_id,
      isUnderWarranty: warrantyStatus,
    });
  };

  const updateRequest = (status, e) => {
    if (status === "rejected") {
      triggerReject();
    } else if (status === "accepted") {
      openAvailabilityModal();
    } else if (status === "awaiting-approval") {
      pushForApproval(e);
    } else if (status === "repair_time_set") {
      openRepairTimeModal();
    } else if (status === "completed") {
      completeRequest(e);
    } else {
      //only for trasition from accepted to start-work (ongoing)"
      const body = { status };

      const beforeImagesFormData = new FormData();
      for (const image of beforeImages) {
        beforeImagesFormData.append("photo", image);
      }
      const asyncFunction = () =>
        updateServiceRequests({
          requestId: viewedDetail?.request_id,
          body,
          pageNumber: currentPage,
          serviceCenterId: auth?.auth_id,
          beforeImages: beforeImages !== "" ? beforeImagesFormData : "",
        });
      deleteDialog({ Message: "Update Service Request", asyncFunction });
    }
  };

  const triggerReject = () => {
    openRejectionReasonModal();
  };

  const rejectionSubmit = (e) => {
    e.preventDefault();
    setRejectionReasonModal(false);
    const body = { status: "rejected", reason: rejectionReason };
    const asyncFunction = () =>
      updateServiceRequests({
        requestId: viewedDetail?.request_id,
        body,
        pageNumber: currentPage,
        serviceCenterId: auth?.auth_id,
      });
    deleteDialog({ Message: "Update Service Request", asyncFunction });
  };

  const acceptRequest = (e) => {
    e.preventDefault();
    const body = {
      status: "accepted",
      availability_date: moment(availability).format("YYYY-MM-DD"),
      availability_time: moment(availability).format("HH:MM"),
    };
    const asyncFunction = () =>
      updateServiceRequests({
        requestId: viewedDetail?.request_id,
        body,
        pageNumber: currentPage,
        serviceCenterId: auth?.auth_id,
      });
    closeAvailabilityModal();
    deleteDialog({ Message: "Update Service Request", asyncFunction });
  };
  const updateRepairTIme = (e) => {
    e.preventDefault();
    const body = {
      status: "repair_time_set",
      repair_date: moment(repairTime).format("YYYY-MM-DD"),
      repair_time: moment(repairTime).format("HH:MM"),
    };
    const asyncFunction = () =>
      updateServiceRequests({
        requestId: viewedDetail?.request_id,
        body,
        pageNumber: currentPage,
        serviceCenterId: auth?.auth_id,
      });
    closeRepairTimeModal();
    deleteDialog({ Message: "Update Service Request", asyncFunction });
  };

  const pushForApproval = (e) => {
    e.preventDefault();
    const body = !viewedDetail?.covered_by_warranty
      ? {
          status: "awaiting-approval",
          name,
          diagnostics_act: diagnosticActivity,
          quotations,
        }
      : {
          status: "awaiting-approval",
          name,
          diagnostics_act: diagnosticActivity,
        };
    const beforeImagesFormData = new FormData();
    const afterImagesFormData = new FormData();
    const signatureFormData = new FormData();
    for (const image of beforeImages) {
      beforeImagesFormData.append("photo", image);
    }
    for (const image of afterImages) {
      afterImagesFormData.append("photo", image);
    }
    for (const image of signature) {
      signatureFormData.append("photo", image);
    }
    const asyncFunction = () =>
      updateServiceRequests({
        requestId: viewedDetail?.request_id,
        body,
        pageNumber: currentPage,
        serviceCenterId: auth?.auth_id,
        //because they are uptional andmay not be provided
        beforeImages: beforeImages !== "" ? beforeImagesFormData : "",
      });
    deleteDialog({ Message: "Update Service Request", asyncFunction });
  };

  const completeRequest = (e) => {
    e.preventDefault();
    const body = {
      status: "completed",
      name,
      diagnostics_act: diagnosticActivity,
      specific_act: specificActivity,
      recommendation,
      quotations,
    };
    const beforeImagesFormData = new FormData();
    const afterImagesFormData = new FormData();
    const signatureFormData = new FormData();
    for (const image of beforeImages) {
      beforeImagesFormData.append("photo", image);
    }
    for (const image of afterImages) {
      afterImagesFormData.append("photo", image);
    }
    for (const image of signature) {
      signatureFormData.append("photo", image);
    }

    const asyncFunction = () =>
      updateServiceRequests({
        requestId: viewedDetail?.request_id,
        body,
        pageNumber: currentPage,
        serviceCenterId: auth?.auth_id,
        //because they are uptional andmay not be provided
        // beforeImages: beforeImages !== "" ? beforeImagesFormData : "",
        afterImages: afterImages !== "" ? afterImagesFormData : "",
        signature: signature !== "" ? signatureFormData : "",
      });

    deleteDialog({ Message: "Update Service Request", asyncFunction });
  };

  const onChange = (e, index) => {
    if (index !== undefined) {
      const copiedArray = quotations.slice();
      copiedArray[index][e.target.name] = e.target.value;
      setDischargeFormData({ ...dishchargeFormData, quotations: copiedArray });
    } else if (e.target.name === "beforeImages" || e.target.name === "afterImages" || e.target.name === "signature") {
      setDischargeFormData({
        ...dishchargeFormData,
        [e.target.name]: e.target.files,
      });
    } else {
      setDischargeFormData({
        ...dishchargeFormData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const assign = (e) => {
    e.preventDefault();
    assignDeffectToCenter({ requestId: id, serviceCenterId, comment });
  };

  return (
    <div>
      {!getLoading && (
        <Card>
          <CardTitle></CardTitle>
          <CardBody>
            <div>
              <div className="d-flex justify-content-between mb-4">
                <div className="fw-bold">View Service Request</div>
                <small className="px-3 py-1 rounded capitlize" style={{ backgroundColor: "#FCF4E8", color: "#E5870D" }}>
                  {viewedDetail?.status === "awaiting-approval" ? "Awaiting Approval" : viewedDetail?.status === "repair_time_set" ? "Operation Time Set" : viewedDetail?.status}
                </small>
              </div>
              <div className="modals-grid col col-md-7">
                <div className="">
                  <small>Request Type</small>
                  <div className="capitlize">{viewedDetail?.service_type}</div>
                </div>
                {viewedDetail?.image && (
                  <div className="">
                    <small>Image where applicable</small>
                    <div style={{ color: "#19AF7C" }}>
                      <u onClick={() => showImages(viewedDetail?.image)}>view image</u>
                    </div>
                  </div>
                )}
                {viewedDetail?.description && (
                  <div className="">
                    <small>Description</small>
                    <div>{viewedDetail?.description}</div>
                  </div>
                )}

                <div className="">
                  <small>Driver Phone Number</small>

                  <div>{viewedDetail?.driver_phone_number}</div>
                </div>
                <div className="">
                  <small>Vehicle Milage Reading</small>

                  {/* <div>{viewedDetail?.service_type === "maintenance" ? viewedDetail?.mileage_raised : vehicleMileage}</div> */}
                  <div>{viewedDetail?.mileage_raised}</div>
                </div>
                {viewedDetail?.oem_comment && (
                  <div className="">
                    <small>Oem Comment</small>
                    <div>{viewedDetail?.oem_comment}</div>
                  </div>
                )}
                {viewedDetail?.measures && (
                  <div className="">
                    <small>Measures taken</small>
                    <div>{viewedDetail?.measures}</div>
                  </div>
                )}
                <div className="">
                  <small>Service center assigned to</small>
                  <div className="capitlize"> {viewedDetail?.service_center_name}</div>
                </div>
                <div className="">
                  <small>Driver name</small>
                  <div>{viewedDetail?.driver_name}</div>
                </div>
                {viewedDetail?.service_type !== "maintenance" && (
                  <div className="">
                    <small>Urgency</small>
                    <div className={`${viewedDetail?.urgency === "high" ? "text-danger" : "text-warning"} capitlize fw-bold `}>{viewedDetail?.urgency}</div>
                  </div>
                )}
                <div className="">
                  <small>Vehice Model</small>
                  <div className="capitlize">{viewedDetail?.vehicle_model}</div>
                </div>
                <div className="">
                  <small>Time of request</small>
                  <div>{formatTime(viewedDetail?.createdAt).fullDateTime}</div>
                </div>
                {viewedDetail?.repair_date && (
                  <div className="">
                    <small>Scheduled time for operation </small>
                    <div>{formatTime(viewedDetail?.repair_date).fullDateTime}</div>
                  </div>
                )}
                {viewedDetail?.status === "rejected" && (
                  <div className="">
                    <small>Rejection reason</small>
                    <div className="text-danger">{viewedDetail?.reason}</div>
                  </div>
                )}
                {viewedDetail?.status === "accepted" && (
                  <div className="">
                    <small>Service center availability time for inspection</small>
                    <div className="">{formatTime(viewedDetail?.availabily_date).fullDateTime}</div>
                  </div>
                )}

                <div className="">
                  <small>Covered by warranty</small>
                  {/* {viewedDetail?.status === "pending" && ( */}
                  <span className="m text-white badge bg-primary py-1 ms-2">
                    <span className="d-flex align-items-center cursor-pointer" onClick={() => setWarrantyModal(true)}>
                      <span className="me-1">Update Warranty Status</span>
                    </span>
                  </span>

                  {/* )} */}
                  <div className={`${viewedDetail?.covered_by_warranty ? "text-success" : "text-danger"} fw-bold`}>{viewedDetail?.covered_by_warranty ? "Yes" : "No"}</div>
                </div>
                <div className="">
                  <small>Plate number</small>
                  <div>{viewedDetail?.plate_number}</div>
                </div>

                {!maintenanceHistory && (
                  <div className="">
                    <small>Vehice maintenance and Repairs history</small>
                    <div style={{ color: "#19AF7C" }}>
                      <Link to={`/maintenance-history/${viewedDetail?.vehicle_id}`} style={{ textDecoration: "none" }}>
                        <u>view maintenance history</u>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              {viewedDetail?.status !== "pending" && viewedDetail?.status !== "accepted" && viewedDetail?.status !== "rejected" && (
                <>
                  <div className="mt-4">
                    <small className="text-primary">DIAGNOSTIC INFORMATION</small>{" "}
                  </div>
                  <div className="mt-3">Diagnostic activity carried out </div>
                  <div className="mt-2">{viewedDetail?.diagnostics_data?.diagnostics_act}</div>

                  {viewedDetail?.status === "completed" && (
                    <>
                      <div className="mt-3">Specific activity carried out </div>
                      <div className="mt-2">{viewedDetail?.diagnostics_data?.specific_act}</div>

                      <div className="mt-3">Recommendation </div>
                      <div className="mt-2">{viewedDetail?.diagnostics_data?.recommendation}</div>
                    </>
                  )}

                  <div className="mt-4">
                    <div className="modals-grid col col-md-7">
                      {viewedDetail?.status === "completed" && (
                        <>
                          {viewedDetail?.start_time && (
                            <div className="">
                              <small>Start time</small>
                              <div className="capitlize">{formatTime(viewedDetail?.start_time).fullDateTime}</div>
                            </div>
                          )}

                          {viewedDetail?.completion_time && (
                            <div className="">
                              <small>Discharge time</small>
                              <div className="capitlize">{formatTime(viewedDetail?.completion_time).fullDateTime}</div>
                            </div>
                          )}
                        </>
                      )}

                      {viewedDetail?.diagnostics_data?.before_images.length > 0 && (
                        <div className="">
                          <small>Before images</small>
                          <div className="py-2 px-3 rounded d-flex justify-content-between" style={{ backgroundColor: "#F5F5F5" }}>
                            <small>Image file</small>
                            <div className="text-teal">
                              <u className="cursor-pointer" onClick={() => showImages(viewedDetail?.diagnostics_data?.before_images)}>
                                View
                              </u>
                            </div>
                          </div>
                        </div>
                      )}

                      {viewedDetail?.status === "completed" && (
                        <>
                          {viewedDetail?.diagnostics_data?.after_images.length > 0 && (
                            <div className="">
                              <small>After images</small>
                              <div className="py-2 px-3 rounded d-flex justify-content-between" style={{ backgroundColor: "#F5F5F5" }}>
                                <small>image file</small>
                                <div className="text-teal">
                                  <u className="cursor-pointer" onClick={() => showImages(viewedDetail?.diagnostics_data?.after_images)}>
                                    View
                                  </u>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="">
                            <small>Rep name</small>
                            <div className="capitlize">{viewedDetail?.diagnostics_data?.name}</div>
                          </div>
                          {viewedDetail?.diagnostics_data?.signature && (
                            <div className="">
                              <small>Rep signature</small>
                              <div className="py-2 px-3 rounded d-flex justify-content-between" style={{ backgroundColor: "#F5F5F5" }}>
                                <small>image file</small>
                                <div className="text-teal">
                                  <u onClick={() => showImages(viewedDetail?.diagnostics_data?.signature)}>View</u>
                                </div>
                              </div>
                            </div>
                          )}

                          {viewedDetail?.invoice && (
                            <div className="">
                              <small>Invoice</small>
                              <div className="py-2 px-3 rounded d-flex justify-content-between" style={{ backgroundColor: "#F5F5F5" }}>
                                <small>image file</small>
                                <div className="text-teal">
                                  <u>
                                    <a href={viewedDetail?.invoice} download>
                                      Download Invoice
                                    </a>
                                  </u>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {!justView && (
                <div className="col-12 col-md-10">
                  <form className="" onSubmit={(e) => (viewedDetail?.status === "accepted" ? updateRequest("awaiting-approval", e) : viewedDetail?.status === "ongoing" ? updateRequest("completed", e) : null)} style={{ maxWidth: "100%" }}>
                    {(viewedDetail?.status === "accepted" || viewedDetail?.status === "ongoing") && (
                      <div className="mt-4 mb-2">
                        <small className="text-primary">DIAGNOSTIC INFORMATION</small>{" "}
                      </div>
                    )}

                    <div className="grid-md">
                      {viewedDetail?.status === "accepted" && (
                        <FormGroup>
                          <Label for="diag">Diagnostic activity carried out </Label>
                          <Input id="diag" name="diagnosticActivity" type="textarea" value={diagnosticActivity} minlength="50" rows={3} onChange={onChange} required />
                        </FormGroup>
                      )}

                      {viewedDetail?.status === "ongoing" && (
                        <FormGroup>
                          <Label for="spec">Specific activity carried out </Label>
                          <Input id="spec" name="specificActivity" type="textarea" value={specificActivity} rows={3} minlength="50" onChange={onChange} required />
                        </FormGroup>
                      )}

                      {(viewedDetail?.status === "accepted" || (viewedDetail?.status === "repair_time_set" && viewedDetail?.service_type === "maintenance")) && (
                        <FormGroup>
                          <Label className="mb-1" for="bImg">
                            Before images (Where applicable)
                          </Label>
                          <br />
                          <Input id="bImg" name="beforeImages" type="file" accept=".jpg, .jpeg, .png" onChange={onChange} />
                        </FormGroup>
                      )}

                      {(viewedDetail?.status === "accepted" || viewedDetail?.status === "ongoing") && (
                        <FormGroup>
                          <Label for="name">Name</Label>
                          <Input id="name" name="name" type="text" onChange={onChange} value={name} required />
                        </FormGroup>
                      )}

                      {viewedDetail?.status === "ongoing" && (
                        <>
                          <FormGroup>
                            <Label className="mb-1" for="aImg">
                              After images (Where applicable)
                            </Label>
                            <br />
                            <Input id="aImg" name="afterImages" type="file" onChange={onChange} accept=".jpg, .jpeg, .png" />
                            {/* <FormText>This is some placeholder block-level help text for the above input. It's a bit lighter and easily wraps to a new line.</FormText> */}
                          </FormGroup>
                          <FormGroup>
                            <Label className="mb-1" for="sign">
                              Signature
                            </Label>
                            <br />
                            <Input id="sign" name="signature" type="file" accept=".jpg, .jpeg, .png" onChange={onChange} />
                          </FormGroup>

                          <FormGroup>
                            <Label for="rec">Recommendation</Label>
                            <Input id="rec" name="recommendation" type="text" onChange={onChange} minlength="15" value={recommendation} required />
                          </FormGroup>
                        </>
                      )}
                    </div>
                    {/* QUOTATION */}
                    {!viewedDetail?.covered_by_warranty && viewedDetail?.status === "accepted" && (
                      <div className="col-12  w-100">
                        <div className="mt-4 mb-2 d-flex justify-content-between">
                          <div>
                            <small className="text-primary">QUOTATION</small>
                          </div>
                          <div>
                            <small
                              className="text-teal cursor-pointer"
                              onClick={() =>
                                setDischargeFormData({
                                  ...dishchargeFormData,
                                  quotations: [
                                    ...quotations,
                                    {
                                      item: "",
                                      description: "",
                                      quantity: "",
                                      amount: "",
                                    },
                                  ],
                                })
                              }
                            >
                              <u>Add Item</u>
                            </small>
                          </div>
                        </div>
                        {quotations.map((item, index) => (
                          <div className="row" key={index}>
                            <div className="col-12 col-md-3">
                              <FormGroup>
                                <Label for="brand-name">Items</Label>
                                <Input id="brand-name" name="item" type="text" onChange={(e) => onChange(e, index)} value={quotations[index].items} required />
                              </FormGroup>
                            </div>
                            <div className="col-12 col-md-4">
                              <FormGroup>
                                <Label for="brand-name">Description</Label>
                                <Input id="brand-name" name="description" type="text" onChange={(e) => onChange(e, index)} value={quotations[index].description} required />
                              </FormGroup>
                            </div>
                            <div className="col-6 col-md-2">
                              <FormGroup>
                                <Label for="brand-name">Quantity</Label>
                                <Input id="brand-name" name="quantity" type="number" onChange={(e) => onChange(e, index)} value={quotations[index].quantity} required />
                              </FormGroup>
                            </div>
                            <div className="col-6 col-md-3">
                              <div className="d-flex align-items-center">
                                <FormGroup>
                                  <Label for="brand-name">Amount</Label>
                                  <Input id="brand-name" name="amount" type="number" onChange={(e) => onChange(e, index)} value={quotations[index].amount} required />
                                </FormGroup>
                                <div className="cursor-pointer mt-3 ms-2">
                                  <FcCancel size="20" color="red" onClick={() => onDeleteFormRow(index)} />
                                </div>
                              </div>
                            </div>
                            {<hr className="d-md-none my-3" />}
                          </div>
                        ))}
                      </div>
                    )}
                    {viewedDetail?.status !== "completed" && viewedDetail?.diagnostics_data?.quotations?.length > 0 && <Quotation items={viewedDetail?.diagnostics_data?.quotations} />}
                    {viewedDetail?.status === "accepted" && (
                      <>
                        <Button className="btn-modal-accept cursor-pointer mt-4" type="submit">
                          Request For Approval
                        </Button>
                      </>
                    )}
                    {viewedDetail?.status === "ongoing" && (
                      <Button className="btn-modal-accept cursor-pointer mt-4" type="submit">
                        Finish Work
                      </Button>
                    )}
                  </form>
                </div>
              )}

              <div className="mt-4">
                {!justView && (
                  <>
                    {viewedDetail?.status === "pending" && (
                      <Button className="btn-modal-accept me-2 cursor-pointer" onClick={() => updateRequest("accepted")}>
                        Accept
                      </Button>
                    )}
                    {viewedDetail?.status === "pending" && (
                      <Button className="btn-modal-cancel" onClick={() => updateRequest("rejected")}>
                        Reject
                      </Button>
                    )}
                    {/* {viewedDetail?.status === "accepted" && (
                      <Button className="btn-modal-accept cursor-pointer " onClick={() => updateRequest("awaiting-approval")}>
                        Request for Approval
                      </Button>
                    )} */}

                    {viewedDetail?.status === "approved" && (
                      <Button className="btn-modal-accept cursor-pointer " onClick={() => updateRequest("repair_time_set")}>
                        Set Operation Time
                      </Button>
                    )}
                    {viewedDetail?.status === "repair_time_set" && (
                      <Button className="btn-modal-accept cursor-pointer " onClick={() => updateRequest("ongoing")}>
                        Start Work
                      </Button>
                    )}
                  </>
                )}

                {/* if it is a defect report, the oem assigns to a service center with these buttons */}

                {auth?.user_type === "oem" && viewedDetail?.status === "pending" && viewedDetail?.service_type === "defect" && (
                  <Button className="btn-modal-accept cursor-pointer" onClick={() => setServiceCenterModal(true)}>
                    {serviceCenterId !== undefined ? "Re-assign to another service center" : "Assign to a service center"}
                  </Button>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      )}

      {/* availability date and time modal */}
      <Modal isOpen={availabilityModal} toggle={closeAvailabilityModal} size="sm" scrollable backdrop="static">
        <ModalHeader toggle={closeAvailabilityModal}>
          <div className="">
            <div>Set availability</div>
          </div>
        </ModalHeader>
        <form onSubmit={acceptRequest}>
          <ModalBody style={{ height: 100 }}>
            <div className=" mt-2 text-center ">
              {/* <DateTimePicker onChange={onChange} value={value} /> */}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Availability date and time"
                  value={availability}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    setAvailability(newValue);
                  }}
                />
              </LocalizationProvider>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-modal-accept" type="submit" disabled={postLoading}>
              Submit
            </Button>
            <Button className="btn-modal-cancel" onClick={closeAvailabilityModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      {/* Update warranty status modal */}
      <Modal isOpen={warrantyModal} toggle={closeWarrantyModal} size="sm" scrollable backdrop="static">
        <ModalHeader toggle={closeAvailabilityModal}>
          <div className="">
            <div>Set Warranty Status</div>
          </div>
        </ModalHeader>
        <form onSubmit={warrantUpdate}>
          <ModalBody style={{ height: 100 }}>
            <FormGroup>
              <Label for="brand-name">Covered by warranty</Label>
              <Input
                id="brand-name"
                name="cbw"
                type="select"
                // value={warrantyStatus || viewedDetail?.covered_by_warranty}
                onChange={(e) => setWarrantyStatus(e.target.value)}
                required
              >
                <option value="">- - Select warranty status - -</option>
                <option value="yes" selected={viewedDetail?.covered_by_warranty}>
                  Yes
                </option>
                <option value="no" selected={!viewedDetail?.covered_by_warranty}>
                  No
                </option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-modal-accept" type="submit" disabled={postLoading}>
              Submit
            </Button>
            <Button className="btn-modal-cancel" onClick={closeWarrantyModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      {/* Repair date and time modal */}
      <Modal isOpen={repairTimeModal} toggle={closeRepairTimeModal} size="sm" scrollable backdrop="static">
        <ModalHeader toggle={closeRepairTimeModal}>
          <div className="">
            <div>Set Repair Time</div>
          </div>
        </ModalHeader>
        <form onSubmit={updateRepairTIme}>
          <ModalBody style={{ height: 100 }}>
            <div className=" mt-2 text-center ">
              {/* <DateTimePicker onChange={onChange} value={value} /> */}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Availability date and time"
                  value={repairTime}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    setRepairTime(newValue);
                  }}
                />
              </LocalizationProvider>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-modal-accept" type="submit" disabled={postLoading}>
              Submit
            </Button>
            <Button className="btn-modal-cancel" onClick={closeRepairTimeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      {/* service center list modal */}

      {/*rejection reason modal */}
      <Modal isOpen={rejectionReasonModal} toggle={closeRejectionReasonModal} size="sm" scrollable backdrop="static">
        <ModalHeader toggle={closeRejectionReasonModal}>
          <div className="">
            <div>Reject service request</div>
          </div>
        </ModalHeader>
        <Form onSubmit={rejectionSubmit}>
          <ModalBody style={{ height: 300 }}>
            <div className="mt-2 w-100">
              <FormGroup>
                <Label for="exampleText">Reason</Label>
                <Input type="textarea" name="text" id="exampleText" rows="9" max={100} placeholder="Max 100 characters" value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} required />
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-modal-accept" type="submit" onClick={rejectionSubmit} disabled={postLoading}>
              Submit
            </Button>
            <Button className="btn-modal-cancel" onClick={closeRejectionReasonModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* service center selection modal for assignment of defect */}
      <Modal isOpen={serviceCenterModal} toggle={closeServiceCenterModal} size="md" scrollable backdrop="static">
        <ModalHeader toggle={closeServiceCenterModal}>
          <div className="">
            <div>Assign to Service Center</div>
          </div>
        </ModalHeader>
        <form onSubmit={assign}>
          <ModalBody style={{ height: 250 }}>
            <FormGroup>
              <Label for="serviceCenter">Select Service Center</Label>
              <Input id="serviceCenter" name="serviceCenter" type="select" value={serviceCenterId} required onChange={(e) => setServiceCenterId(e.target.value)}>
                <option hidden value="">
                  -- select service center --
                </option>
                {serviceCenters?.map((item) => (
                  <option key={item?._id} className="fdfd" value={item?.center_data?.auth_id}>
                    {item?.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup className="mt-2">
              <Label for="comment">Comment</Label>
              <Input id="comment" name="comment" type="textarea" value={comment} rows="3" required onChange={(e) => setComment(e.target.value)} />
            </FormGroup>
            <div className="d-flex justify-content-end mt-4">
              <Button className="btn-modal-accept cursor-pointer " type="submit" onClick={() => setServiceCenterModal(true)} disabled={postLoading}>
                Submit
              </Button>
              <Button className="btn-modal-cancel ms-2" onClick={closeServiceCenterModal}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </form>
      </Modal>

      {/* image modal */}
      <Modal isOpen={imageModal} toggle={() => setImageModal(false)} size="md" scrollable>
        {/* <ModalHeader toggle={() => setImageModal(false)}>
          <div className="">
            <div>view image</div>
          </div>
        </ModalHeader> */}
        <ModalBody className="text-center">{imageSrc !== "" && <img src={imageSrc} style={{ maxWidth: "100%" }} />}</ModalBody>
      </Modal>
    </div>
  );
};
export default ServiceRequestDetails;
