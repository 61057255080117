import React, {useContext} from "react";
import {AuthContext} from "../../../contexts/AuthContext";
import ServiceRequestTable from "../shared/ServiceRequestTable";

const ServiceRequest = () => {
  const {auth} = useContext(AuthContext);
  const userType = auth?.user_type;

  return <div>{userType === "oem" ? <ServiceRequestTable justView /> : <ServiceRequestTable />}</div>;
};

export default ServiceRequest;
