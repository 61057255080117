import Loader from "react-loader-spinner"


const LoaderPage = ({ type, size }) => {
    return (
        <div className="d-flex justify-content-center align-item-center " style={{ minHeight: "90vh" }}>
            <Loader
                className="my-loader align-self-center"
                type={type}
                color="#1FBEE7"
                height={size}
                width={size}
            />
        </div>
    )
}

export default LoaderPage
