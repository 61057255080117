import React, {createContext, useState} from "react";

export const ServiceRequestContext = createContext();

const ServiceRequestContextProvider = ({children}) => {
  const [serviceRequests, setServiceRequests] = useState([]);
  const [serviceRequestsCount, setServiceRequestsCount] = useState(0);
  const [serviceRequest, setServiceRequest] = useState({}); // for a single service request
  const [serviceRequestBreakdownByType, setServiceRequestBreakdownByType] = useState([]);
  const [serviceRequestBreakdownByStatus, setServiceRequestBreakdownByStatus] = useState([]);
  const [serviceRequestBreakdownByCenter, setServiceRequestBreakdownByCenter] = useState([]);
  const [defectReportCount, setDefectReportCount] = useState(0);

  return (
    <ServiceRequestContext.Provider
      value={{
        serviceRequests,
        setServiceRequests,
        serviceRequestsCount,
        setServiceRequestsCount,
        serviceRequest,
        setServiceRequest,
        serviceRequestBreakdownByType,
        setServiceRequestBreakdownByType,
        serviceRequestBreakdownByStatus,
        setServiceRequestBreakdownByStatus,
        serviceRequestBreakdownByCenter,
        setServiceRequestBreakdownByCenter,
        defectReportCount,
        setDefectReportCount,
      }}
    >
      {children}
    </ServiceRequestContext.Provider>
  );
};

export default ServiceRequestContextProvider;
