import {lazy} from "react";
import Overview from "../Pages/others/Overview/Overview";
import ServiceCenterProfile from "../Pages/others/Profile/ServiceCenterProfile";
import ServiceCenters from "../Pages/others/serviceCenters/ServiceCenters";
import MaintenanceDetails from "../Pages/others/serviceRequests/maintenanceHistory/maintenanceHistoryDetails";
import VehicleMaintenanceHistory from "../Pages/others/serviceRequests/maintenanceHistory/VehicleMaintenanceHistory";
import ServiceRequest from "../Pages/others/serviceRequests/ServiceRequest";
import ServiceRequestDetails from "../Pages/others/serviceRequests/ServiceRequestDetails";
import VehicleDetails from "../Pages/others/vehicleManagement/vehicleDetails/VehicleDetails";
import VehicleManagement from "../Pages/others/vehicleManagement/VehicleManagement";
import WorkOrder from "../Pages/others/workOrder/WorkOrder";

const Signup = lazy(() => import("../Pages/auth/Signup"));
const Signin = lazy(() => import("../Pages/auth/Signin"));
const ForgotPassword = lazy(() => import("../Pages/auth/ForgotPassword"));
const Verification = lazy(() => import("../Pages/auth/Verification"));
// const Plans = lazy(() => import("../Pages/others/business/"));
const RegistrationConfirmation = lazy(() => import("../Pages/auth/RegistrationConfirmation"));
const ResetPassword = lazy(() => import("../Pages/auth/ResetPassword"));

export const authRoutes = [
  // {path: "/signup", component: Signup, title: "Signup", leadText: "Create Account"},
  {path: "/registration-confirmation/", component: RegistrationConfirmation, title: "Signup", leadText: "Registration Confirmation"},
  {path: "/signin", component: Signin, title: "Signin", leadText: ""},
  {path: "/verify-email/", component: Verification, title: "verify-email", leadText: "Email Verification"},
  {path: "/forgot-password", component: ForgotPassword, title: "forgot-password", leadText: ""},
  {path: "/reset-password/:id", component: ResetPassword, title: "reset-password", leadText: ""},
];

export const userRoutes = [
  {path: "/", component: Overview, title: "Overview", leadText: "Overview"},
  {path: "/vehicle-management", component: VehicleManagement, title: "vehicles", leadText: "Vehicle"},
  {path: "/service-requests", component: ServiceRequest, title: "service-requests", leadText: "Service requests"},
  {path: "/service-requests/:id", component: ServiceRequestDetails, title: "service-requests", leadText: "Request details"},
  {path: "/service-centers", component: ServiceCenters, title: "service-centers", leadText: "Service centers"},
  {path: "/service-centers/:id", component: ServiceCenterProfile, title: "profile", leadText: "Center profile"},
  {path: "/vehicle-management/:id", component: VehicleDetails, title: "vehicle", leadText: "Vehicle"},
  // {path: "/service-request-work-order", component: WorkOrder, title: "service-requests", leadText: "Service requests"}, // for service centers
  {path: "/maintenance-history/:id", component: VehicleMaintenanceHistory, title: "maintenance-history", leadText: "Maintenance History"}, // for service centers
  // {path: "/maintenance-history/:id/details", component: MaintenanceDetails, title: "maintenance-history", leadText: "Maintenance History"}, // for service centers
];
