import { useContext, useState } from "react";
import { FaPowerOff } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { AppPrefrenceContext } from "../contexts/AppPrefrenceContext";
import "../assets/scss/sidebar.scss";
import { useAuthApiServices } from "../services/AuthApiServices";
import { AuthContext } from "../contexts/AuthContext";
import { deleteDialog } from "../helpers/deleteDialogHelper";
import logo from "../assets/img/logo.png";

const SideBar = () => {
  const { expanded, setExpanded } = useContext(AppPrefrenceContext);

  const { auth } = useContext(AuthContext);
  const { logoutUser } = useAuthApiServices();

  const menuItems =
    auth?.user_type === "oem"
      ? [
          { title: "Overview", linkTo: "/" },
          { title: "Vehicle Management", linkTo: "/vehicle-management" },
          { title: "Service Requests", linkTo: "/service-requests" },
          { title: "Service Centers", linkTo: "/service-centers" },
        ]
      : [
          { title: "Overview", linkTo: "/" },
          { title: "Service Requests", linkTo: "/service-requests" },
          { title: "Profile", linkTo: "/service-centers/profile" },
        ];

  return (
    <div className={`sidebar ${expanded && "expanded"}`}>
      <div
        className="bg-white mx-auto rounded p-2 w-50"
        style={{ marginTop: 10 }}
      >
        <Link to="/">
          <img src={logo} alt="logo" height={60} />
        </Link>
      </div>
      <div className="menu-group">
        {menuItems.map((item, i) => (
          <div key={i} onClick={() => setExpanded(false)}>
            <NavLink
              // exact
              end //end replaces exactart
              to={item.linkTo}
              className="menu-item d-block text-dark"
            >
              {item.title}
            </NavLink>
          </div>
        ))}
      </div>
      <div className="text-center">
        <div
          className="px-3 py-3 bg-white rounded-circle d-inline cursor-pointer"
          onClick={() =>
            deleteDialog({ Message: "Log Out", asyncFunction: logoutUser })
          }
        >
          <FaPowerOff size={35} color="#1fbee7" />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
