import React, { useContext, useEffect, useState } from "react";
import { Card, Table } from "reactstrap";
import { FaEye } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useServiceRequestsApiServices } from "../../../../services/serviceRequestApiServices";
import { useQueryStringHelper } from "../../../../helpers/queryStringHelper";
import EmptyPage from "../../../../components/EmptyPage";
import { usePaginationHelper } from "../../../../helpers/paginationHelper";
import { serviceRequestType } from "../../../../data/serviceRequestTypes";
import { ServiceRequestContext } from "../../../../contexts/ServiceRequestContext";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import Pagination from "react-js-pagination";
import { formatTime } from "../../../../helpers/timeFormatHelper";
import { deleteDialog } from "../../../../helpers/deleteDialogHelper";

const VehicleMaintenanceHistory = () => {
  const { id } = useParams();

  const { auth } = useContext(AuthContext);
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const { serviceRequests, serviceRequestsCount } = useContext(ServiceRequestContext);

  const { getServiceRequests, getServiceRequestsCount, getServiceRequestsExport } = useServiceRequestsApiServices();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [reqType, setReqType] = useState("");
  const { paginate } = usePaginationHelper();
  const { getLoading } = useContext(LoadingContext);

  useEffect(async () => {
    getServiceRequests({
      spinner: true,
      page: currentPage,
      reqType,
      status: "completed",
      vehicleId: id,
    });
    getServiceRequestsCount({
      reqType,
      status: "completed",
      vehicleId: id,
    });
  }, [reqType]);

  const onPaginate = (pageNumber) => {
    paginate({ pageNumber, setCurrentPage, vehicleId: "", callbackFunction: getServiceRequests({ spinner: false, page: pageNumber }) });
  };

  const onExport = () => {
    const asyncFunction = () =>
      getServiceRequestsExport({
        serviceCenterId: auth?.auth_id,
        reqType,
        vehicleId: id,
      });

    deleteDialog({ Message: "Export Service Requests", asyncFunction });
  };
  return (
    <div>
      {!getLoading && (
        <Card body>
          <div className="m-3 d-flex ">
            <div className="btn btn-success bt-sm py-1 cursor-pointer" onClick={onExport}>
              Export
            </div>
          </div>
          <Table borderless striped responsive className="text-nowrap">
            <thead>
              <th className="fw-normal">
                <select type="select" className="b-0" style={{ border: 0, outline: 0 }} onChange={(e) => setReqType(e.target.value)}>
                  <option value="">Request type </option>
                  {serviceRequestType.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </th>
              <th className="fw-normal">Time of request</th>
              <th className="fw-normal">Service center</th>
              <th className="fw-normal">Completion time</th>
              {/* <th className="fw-normal">TAT</th> */}
            </thead>
            <tbody>
              {serviceRequests.length > 0 &&
                serviceRequests.map((item, index) => (
                  <tr>
                    <td>{item.service_type}</td>
                    <td>{formatTime(item?.createdAt).fullDateTime}</td>
                    <td>{item.service_center_name}</td>
                    <td>{formatTime(item?.completion_time).fullDateTime}</td>
                    {/* <td>24 hrs</td> */}
                    <td className="text-teal">
                      {/* <Link to={`/maintenance-history/:id/details`}> */}
                      <Link to={`/service-requests/${item?._id}`} state={{ maintenanceHistory: true }}>
                        <span className="text-teal fw-bold">
                          <FaEye />
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {serviceRequests.length < 1 && <EmptyPage text="No history" />}
          <div>{serviceRequests.length > 0 && <Pagination activePage={currentPage} itemsCountPerPage={20} totalItemsCount={serviceRequestsCount} onChange={onPaginate} itemClass="page-item" linkClass="page-link" />}</div>
        </Card>
      )}
    </div>
  );
};

export default VehicleMaintenanceHistory;
