import React, {useContext} from "react";
import {AuthContext} from "../../../contexts/AuthContext";
import OverviewOem from "./OverviewOem";
import OverviewServiceCenter from "./OverviewServiceCenter";

const Overview = () => {
  const {auth} = useContext(AuthContext);
  // console.log(auth);

  return <div>{auth?.user_type === "oem" ? <OverviewOem /> : <OverviewServiceCenter />}</div>;
};

export default Overview;
