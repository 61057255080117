import React, {useState, useEffect, useContext} from "react";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText, Table} from "reactstrap";
import {VehicleContext} from "../../../../contexts/VehiclesContext";
import {useVehicleServicesApiServices} from "../../../../services/VehicleServicesApiService";
import {useHistory, useParams} from "react-router-dom";
import VehicleServicestable from "../vehicleServices/VehicleServicestable";
import {VehicleServicesContext} from "../../../../contexts/VehicleServicesContext";
const qs = require("qs");
const WarrantyTab = ({openVehicleServiceModal, serviceType}) => {
  const {vehicleServices, vehicleServicesCount} = useContext(VehicleServicesContext);

  const buttons = (
    <>
      <Button className="btn-modal-accept me-2" onClick={() => openVehicleServiceModal("")}>
        Add Service Request Covered
      </Button>
      <Button className="btn-modal-accept me-2" onClick={() => openVehicleServiceModal("part")}>
        Add Spare Parts Covered
      </Button>
    </>
  );

  return (
    <div>
      <div className="d-flex "></div>
      <div className="fw-bold text-muted mt-2">SERVICE REQUESTS / PARTS COVERED</div>
      <div className="fw-bold d-flex justify-content-end">
        {/* {
        } */}
        {vehicleServicesCount > 0 && buttons}
      </div>
      <div>
        <VehicleServicestable serviceType={""} openVehicleServiceModal={openVehicleServiceModal} buttonsComponent={buttons} />
      </div>
    </div>
  );
};

export default WarrantyTab;
