import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Card, CardImg, CardText, CardBody, CardLink, CardTitle, CardSubtitle} from "reactstrap";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText} from "reactstrap";
import {ServiceCentersContext} from "../../../contexts/ServiceCentersContext";
import {useServiceCenterApiServices} from "../../../services/serviceCentersApiServices";
import {BiDownload} from "react-icons/bi";
import {RiFileUploadLine} from "react-icons/ri";
import {AuthContext} from "../../../contexts/AuthContext";
import {NotificationManager} from "react-notifications";
import {LoadingContext} from "../../../contexts/LoadingContext";

const ServiceCenterProfile = () => {
  //note that the center for which profile is fethced ; if the current usertype is "oem",  it comes with the params form Link, but if the usertype is "service center" itself, the id is passed as props

  const {id} = useParams();
  const {getServiceCenter, uploadAgreement} = useServiceCenterApiServices();
  const {serviceCenter} = useContext(ServiceCentersContext);
  const [agreement, setAgreement] = useState("");
  const {auth} = useContext(AuthContext);
  const {getLoading} = useContext(LoadingContext);

  const centerID = auth?.user_type === "oem" ? id : auth?.auth_id;

  useEffect(() => {
    getServiceCenter({spinner: true, centerID});
  }, []);

  const agreementUpload = (e) => {
    if (e.target.files[0].size / 1024 > 500) {
      NotificationManager.error("File size too large");
    } else {
      setAgreement(e.target.files[0]);
      const body = new FormData();
      body.append("doc", e.target.files[0]);
      uploadAgreement({body, centerID: id});
      setAgreement("");
    }
  };
  return (
    <div>
      {
        // !getLoading &
        <Card body>
          {/* <CardTitle className="d-flex justify-content-between text-primary">SERVICE CENTER INFO</CardTitle> */}
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mt-4 text-primary">SERVICE CENTER INFO</div>

              <ul className="list-group ">
                <li className="list-group-item text-end">
                  <span className="pull-left">Center name</span>
                  {serviceCenter?.name}
                </li>
                <li className="list-group-item text-end">
                  <span className="pull-left">Center address</span>
                  {serviceCenter?.address}
                </li>
                <li className="list-group-item text-end">
                  <span className="pull-left">Center email</span>
                  {serviceCenter?.email}
                </li>
                <li className="list-group-item text-end">
                  <span className="pull-left">Center phone number</span>
                  {serviceCenter?.phone_number}
                </li>
                <li className="list-group-item text-end">
                  <span className="pull-left">Opening hour</span>
                  {serviceCenter?.center_data && serviceCenter?.center_data[0]?.opening_hour}
                </li>
                <li className="list-group-item text-end">
                  <span className="pull-left">Closing hour</span>
                  {serviceCenter?.center_data && serviceCenter?.center_data[0]?.closing_hour}
                </li>
                {auth?.user_type === "oem" && serviceCenter?.center_data && (
                  <li className="list-group-item text-end">
                    <span className="pull-left">
                      Agreement
                      <small className="text-danger"> 
                       500kb (.doc, .pdf)</small>
                    </span>

                    <div className="d-flex justify-content-end">
                      {serviceCenter?.center_data[0]?.agreement && (
                        <a href={serviceCenter?.center_data[0]?.agreement} download>
                          <span className="me-2 text-white badge bg-info py-2">
                            <span className="d-flex align-items-center cursor-pointer">
                              <span className="me-1">Download</span>
                              <BiDownload color="white" />
                            </span>
                          </span>
                        </a>
                      )}
                      <span className=" text-white badge bg-success ">
                        <label for="upload" className="w-100 h-100">
                          <input type="file" id="upload" className="d-none" onChange={agreementUpload} value={agreement} accept=".doc, .docx,.txt,.pdf" />
                          <span className="d-flex align-items-center cursor-pointer">
                            <span className="me-1">{serviceCenter?.center_data[0]?.agreement ? "Update" : "Upload"}</span>
                            <RiFileUploadLine color="white" />
                          </span>
                        </label>
                      </span>
                    </div>
                  </li>
                )}
                <li className="list-group-item text-end">
                  <span className="pull-left">Comment</span>
                  {serviceCenter?.center_data && serviceCenter?.center_data[0]?.comment}
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mt-4 text-primary">REP INFORMATION</div>
              <ul className="list-group ">
                <li className="list-group-item text-end">
                  <span className="pull-left">Rep name</span>
                  {serviceCenter?.center_data && serviceCenter?.center_data[0]?.rep_name}
                </li>
                <li className="list-group-item text-end">
                  <span className="pull-left">Rep email</span>
                  {serviceCenter?.center_data && serviceCenter?.center_data[0]?.rep_email}
                </li>
                <li className="list-group-item text-end">
                  <span className="pull-left">Rep phone number</span>
                  {serviceCenter?.center_data && serviceCenter?.center_data[0]?.rep_phone_number}
                </li>
              </ul>
            </div>
          </div>
        </Card>
      }
    </div>
  );
};

export default ServiceCenterProfile;
