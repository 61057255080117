import React, {useContext, useState} from "react";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText, Table} from "reactstrap";
import {VehicleServicesContext} from "../../../../contexts/VehicleServicesContext";
import VehicleServicestable from "../vehicleServices/VehicleServicestable";

const SparePartsTab = ({openVehicleServiceModal}) => {
  const {vehicleServices, vehicleServicesCount} = useContext(VehicleServicesContext);

  const button = (
    <div className="fw-bold d-flex justify-content-end">
      <Button className="btn-modal-accept me-2" onClick={() => openVehicleServiceModal("part")}>
        Add Spare Part
      </Button>
    </div>
  );

  return (
    <div>
      <div>
        <div className="fw-bold text-muted mt-2">SPARE PARTS COVERED</div>
        {vehicleServicesCount > 0 && button}
        <VehicleServicestable serviceType={"part"} openVehicleServiceModal={openVehicleServiceModal} buttonsComponent={button} />
      </div>
    </div>
  );
};

export default SparePartsTab;
