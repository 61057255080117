import React, {useContext, useEffect, useState} from "react";
import lagRide from "../../../../assets/img/lagRide.jpg";
import {TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col} from "reactstrap";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormText, Table} from "reactstrap";

import classnames from "classnames";
import WarrantyTab from "./WarrantyTab";
import MaintenanceTab from "./MaintenanceTab";
import SparePartsTab from "./SparePartsTab";
import {useHistory, useParams} from "react-router-dom";
import {useVehicleApiServices} from "../../../../services/vehicleApiService";
import {VehicleContext} from "../../../../contexts/VehiclesContext";
import {formatTime} from "../../../../helpers/timeFormatHelper";
import {getServiceType} from "../../../../helpers/stringsHelper";
import {LoadingContext} from "../../../../contexts/LoadingContext";
import {useVehicleServicesApiServices} from "../../../../services/VehicleServicesApiService";
import {useLocation, useNavigate} from "react-router-dom";
import WarrantyAndMileageForm from "./WarrantyAndMileageForm";
import placeholderImage from "../../../../assets/img/vehicle-placeholder.png";
import {BsFillCameraFill} from "react-icons/bs";
import {maintenanceTimingTypes, warrantyType} from "../../../../data/warrantyTypes";
export let closeVehicleServiceModal;
const VehicleDetails = (props) => {
  const {getVehicle, getWarrantyAndMileage, uploadVehicleImage} = useVehicleApiServices();
  const {createVehicleServices, updateVehicleService} = useVehicleServicesApiServices();
  const {vehicle, warrantyAndMillage} = useContext(VehicleContext);
  const [vehicleServiceModal, setVehicleServiceModal] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const {getLoading} = useContext(LoadingContext);
  const [formData, setFormData] = useState({name: "", description: "", tat: "", warrantyType: "", frequency: "", time: "", maintenanceTimingType: "", duration: "", mileage: ""});
  const {id} = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();
  const navigate = useNavigate();
  const [editedServiceID, setEditedServiceID] = useState("");
  const [vehiclePhoto, setVehiclePhoto] = useState("");

  function toggle(tab) {
    navigate(`${location.pathname}`);
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  useEffect(() => {
    getVehicle({spinner: false, vehicleID: id});
    // getWarrantyAndMileage({oemVehicleId: id});
  }, []);

  closeVehicleServiceModal = () => {
    setFormData({name: "", description: "", tat: "", warrantyType: "", frequency: "", time: "", maintenanceTimingType: "", duration: "", mileage: ""});
    setVehicleServiceModal(false);
    setEditedServiceID("");
  };
  const openVehicleServiceModal = (serviceType, editedService) => {
    if (editedService) {
      setEditedServiceID(editedService?.service_id);
      setFormData({
        name: editedService?.name,
        description: editedService?.description,
        tat: editedService?.tat,
        warrantyType: editedService?.warranty_type,
        frequency: editedService?.frequency_validity,
        time: editedService?.time_validity,

        maintenanceTimingType: editedService?.mileage ? "mileage" : "duration",
        mileage: editedService?.mileage,
        duration: editedService?.maintenance_time,
      });
    }
    setServiceType(serviceType);
    setVehicleServiceModal(true);
  };
  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload =
      serviceType !== "part"
        ? {name: formData?.name, description: formData?.description, tat: formData.tat, mileage: formData?.mileage, maintenance_time: formData?.duration, service_type: serviceType, vehicle_id: id}
        : {name: formData.name, description: formData.description, service_type: serviceType, vehicle_id: id};

    //checks if the tab is for warranty covered repairs/spare parts in order to add warranty data to the payload
    const body = activeTab === "1" ? {...payload, warranty_type: formData?.warrantyType, frequency_validity: formData?.frequency, time_validity: formData?.time} : payload;
    if (!editedServiceID) {
      createVehicleServices({body, oemVehicleId: id, serviceType, warranty: activeTab === "1" ? "1" : "0"});
    } else {
      updateVehicleService({body, oemVehicleId: id, serviceType, serviceId: editedServiceID, warranty: activeTab === "1" ? "1" : "0"});
    }
  };

  const imageChange = (e) => {
    setVehiclePhoto(e.target.files[0]);
    const body = new FormData();
    body.append("photo", e.target.files[0]);
    uploadVehicleImage({body, oemVehicleId: id});
    setVehiclePhoto("");
  };
  console.log(serviceType);
  return (
    <div>
      {!getLoading && (
        <>
          <div className="d-flex flex-wrap justify-content-between text-white p-1 rounded" style={{backgroundColor: "#006AB5"}}>
            <div className="d-flex">
              <div className="bg-white position-relative">
                {!vehicle?.image && <img src={placeholderImage} width={130} height={90} className="rounded" />}
                {vehicle?.image && <img src={vehicle?.image} width={130} height={90} className="rounded" />}
                <div className="rounded-ci">
                  <div className="bg-white border rounded-circle d-inline cursor-pointer position-absolute bottom-0 end-0 ">
                    <label for="upload" className="w-100 h-100 position-absolute">
                      <input type="file" id="upload" className="d-none" value={vehiclePhoto} onChange={imageChange} accept=".jpg, .jpeg, .png" />
                    </label>
                    <BsFillCameraFill size={30} color="#006AB5" className="px-2 py-1" />
                  </div>
                </div>
              </div>
              <div className="ms-3 mt-2">
                <div className="fw-bold" style={{width: 200}}>
                  {`${vehicle?.brand_name} ${vehicle?.model}  ${vehicle?.year}`}
                </div>
                <div className="mt-2">
                  <small className="">
                    {/* <i>Added: 11 July, 2021</i> */}
                    <i>Added: {formatTime(vehicle.createdAt).fullDate}</i>
                  </small>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-end me-2">
              {/* <div className="mt-2">
            <small className="">
              <u>Edit Details</u>
            </small>
          </div> */}
            </div>
          </div>
          <div className="mt-3">
            <Card body>
              <Nav tabs>
                <NavItem>
                  <NavLink className={classnames({active: activeTab === "1"}, "text-muted")} onClick={() => toggle("1")}>
                    Warranty details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({active: activeTab === "2"}, "text-muted")} onClick={() => toggle("2")}>
                    Maintenance
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({active: activeTab === "3"}, "text-muted")} onClick={() => toggle("3")}>
                    Spare parts
                  </NavLink>
                </NavItem>
              </Nav>
              <div>
                {/* <WarrantyAndMileageForm /> */}
                <TabContent activeTab={activeTab} className="mt-3">
                  {activeTab === "1" && (
                    <TabPane tabId="1">
                      <WarrantyTab openVehicleServiceModal={openVehicleServiceModal} serviceType="repair" />
                    </TabPane>
                  )}
                  {activeTab === "2" && (
                    <TabPane tabId="2">
                      <MaintenanceTab openVehicleServiceModal={openVehicleServiceModal} serviceType="maintenance" />
                    </TabPane>
                  )}
                  {activeTab === "3" && (
                    <TabPane tabId="3">
                      <SparePartsTab openVehicleServiceModal={openVehicleServiceModal} serviceType="part" />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </Card>
          </div>
        </>
      )}
      {/* repairs covered Modal */}
      <Modal isOpen={vehicleServiceModal} toggle={closeVehicleServiceModal} size="sm" scrollable backdrop="static">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={closeVehicleServiceModal} className="mb-0 pb-1">
            <div className="d-flex justify-content-between">
              <small className="fw-bold">
                {editedServiceID ? "Update" : "Add"} {getServiceType(serviceType)} {activeTab === "1" && "Covered"}
              </small>
            </div>
          </ModalHeader>
          <ModalBody style={{height: 300}}>
            <div className=" w-100">
              {activeTab === "1" && (
                <FormGroup className="mt-2">
                  <Label for="type">Service type</Label>
                  <Input id="type" name="serviceType" type="select" value={serviceType} required onChange={(e) => setServiceType(e.target.value)}>
                    <option hidden value="">
                      -- select service type --
                    </option>
                    <option value="repair">Repairs</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="defect">Defect</option>
                  </Input>
                </FormGroup>
              )}
              <FormGroup className="mt-2">
                {/* <Label for="name">{serviceType === "repair" ? "Service request" : serviceType.slice(0, 1).toUpperCase() + serviceType.slice(1)} name</Label> */}
                <Label for="name">{serviceType.slice(0, 1).toUpperCase() + serviceType.slice(1)} name</Label>
                <Input id="name" name="name" type="text" value={formData?.name} required onChange={(e) => handleChange(e)} />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input id="description" name="description" type="textarea" rows="5" value={formData?.description} required onChange={(e) => handleChange(e)} />
              </FormGroup>
              {serviceType !== "part" && (
                <FormGroup className="mt-2">
                  <Label for="tat">Turn around time (hrs) </Label>
                  <Input id="tat" name="tat" type="number" value={formData?.tat} required onChange={(e) => handleChange(e)} />
                </FormGroup>
              )}

              {serviceType === "maintenance" && (
                <>
                  <FormGroup className="mt-2">
                    <Label for="mileage">Mileage</Label>
                    <Input id="mileage" name="mileage" type="number" value={formData?.mileage} required onChange={(e) => handleChange(e)} />
                  </FormGroup>
                  <FormGroup className="mt-2">
                    <Label for="mileage">Duration (months)</Label>
                    <Input id="duration" name="duration" type="number" value={formData?.duration} required onChange={(e) => handleChange(e)} />
                  </FormGroup>
                </>
              )}

              {activeTab === "1" && (
                <FormGroup className="mt-2">
                  <Label for="tat">Warranty Type</Label>
                  <Input id="tat" name="warrantyType" type="select" value={formData?.warrantyType} required onChange={(e) => handleChange(e)}>
                    <option hidden value="">
                      -- select warranty type --
                    </option>
                    {warrantyType.map((item, i) => (
                      <option key={i} className="fdfd" value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}

              {(formData?.warrantyType === "time" || formData?.warrantyType === "both") && (
                <FormGroup className="mt-2">
                  <Label for="tat">Time validity (years)</Label>
                  <Input id="tat" name="time" type="number" min={0} value={formData?.time} required onChange={(e) => handleChange(e)} />
                </FormGroup>
              )}

              {(formData?.warrantyType === "frequency" || formData?.warrantyType === "both") && (
                <FormGroup className="mt-2">
                  <Label for="frequency">Frequency validity</Label>
                  <Input id="frequency" name="frequency" type="number" min={0} value={formData?.frequency} required onChange={(e) => handleChange(e)} />
                </FormGroup>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" className="btn-modal-accept" onClick={function noRefCheck() {}}>
              Submit
            </Button>
            <Button className="btn-modal-cancel" onClick={closeVehicleServiceModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
export default VehicleDetails;
