import React, {createContext, useState} from "react";

export const ServiceCentersContext = createContext();

const ServiceCentersContextProvider = ({children}) => {
  const [serviceCenters, setServiceCenters] = useState([]);
  const [serviceCentersCount, setServiceCentersCount] = useState(0);
  const [serviceCenter, setServiceCenter] = useState({}); // for a single service center 

  return (
    <ServiceCentersContext.Provider value={{serviceCenters, setServiceCenters, serviceCenter, setServiceCenter, serviceCentersCount, setServiceCentersCount}}>
      {children}
    </ServiceCentersContext.Provider>
  );
};

export default ServiceCentersContextProvider;
