import AppPrefrenceContextProvider from "./contexts/AppPrefrenceContext";
import RoutesComponent from "./routes/router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContextProvider from "./contexts/AuthContext";
import LoadingContextProvider from "./contexts/LoadingContext";
import axios from "axios";
import VehicleContextProvider from "./contexts/VehiclesContext";
import VehicleServicesContextProvider from "./contexts/VehicleServicesContext";
import ServiceCentersContextProvider from "./contexts/ServiceCentersContext";
import ServiceRequestContextProvider from "./contexts/ServiceRequestContext";
import useUrlComp from "./constants/urls";
import NotificationsContextProvider from "./contexts/NotificationsContext";
import { useSocketAction } from "./actions/socketActons";
import { useEffect } from "react";

function App() {
  (function () {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
  })();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace("/signin");
      }
      return Promise.reject(error);
    }
  );

  return (
    <div className={"App"}>
      <ToastContainer position={"top-center"} />
      <AppPrefrenceContextProvider>
        <AuthContextProvider>
          <LoadingContextProvider>
            <VehicleContextProvider>
              <VehicleServicesContextProvider>
                <ServiceCentersContextProvider>
                  <ServiceRequestContextProvider>
                    <NotificationsContextProvider>
                      <RoutesComponent />
                    </NotificationsContextProvider>
                  </ServiceRequestContextProvider>
                </ServiceCentersContextProvider>
              </VehicleServicesContextProvider>
            </VehicleContextProvider>
          </LoadingContextProvider>
        </AuthContextProvider>
      </AppPrefrenceContextProvider>
    </div>
  );
}

export default App;
