import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import axios from "axios";
import {LoadingContext} from "../contexts/LoadingContext";
import {api} from "../constants/urls";
import {NotificationManager} from "react-notifications";
import {VehicleContext} from "../contexts/VehiclesContext";
import {closeAddVehicleModal} from "../Pages/others/vehicleManagement/VehicleManagement";

export const useVehicleApiServices = () => {
  const {setVehicles, setVehiclesCount, setVehicle, setWarrantyAndMillage} = useContext(VehicleContext);
  const {setGetLoading, setPostLoading} = useContext(LoadingContext);

  const getVehicles = async ({spinner = false, page = 1, oemId} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/vehicles/?item_per_page=20&page=${page}&oem_id=${oemId}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setVehicles(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getVehiclesCount = async ({spinner = false, oemId} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/vehicles/?oem_id=${oemId}&component=count`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        const count = res.data?.data?.total ? res.data?.data?.total : 0;
        setVehiclesCount(count);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const createVehicle = async ({body, oemId, vehiclePhoto} = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/vehicles/`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        await uploadVehicleImage({body: vehiclePhoto, oemVehicleId: res.data.data.vehicle_id});
        NotificationManager.success("Vehicle created successfully");
        closeAddVehicleModal();
        getVehicles({spinner: false, page: 1, oemId});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const updateVehicle = async ({body, oemId, vehicleID, vehiclePhoto} = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.put(`${api.oem}/v1.1/vehicles/${vehicleID}`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        await uploadVehicleImage({body: vehiclePhoto, oemVehicleId: res.data.data.vehicle_id});
        NotificationManager.success("Vehicle updated successfully");
        closeAddVehicleModal();
        getVehicles({spinner: false, page: 1, oemId});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const deleteVehicle = async ({vehicleID, oemId} = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.delete(`${api.oem}/v1.1/vehicles/${vehicleID}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Vehicle updated successfully");
        closeAddVehicleModal();
        getVehicles({spinner: false, page: 1, oemId});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const getVehicle = async ({spinner = false, vehicleID} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/vehicles/${vehicleID}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setVehicle(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };

  const getWarrantyAndMileage = async ({spinner = false, oemVehicleId} = {}) => {
    try {
      spinner && setGetLoading(true);
      const res = await axios.get(`${api.oem}/v1.1/vehicles/warranty/${oemVehicleId}`);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        setWarrantyAndMillage(res.data.data);
      }
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const createWarrantyAndMileage = async ({body, oemVehicleId} = {}) => {
    // console.log(body);
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.oem}/v1.1/vehicles/warranty/${oemVehicleId}`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        NotificationManager.success("Warranty and Mileage set successfully");
        getWarrantyAndMileage({spinner: false, oemVehicleId});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error please try again or check your internet connection ");
    }
  };
  const uploadVehicleImage = async ({body, oemVehicleId} = {}) => {
    try {
      setPostLoading(true);
      const res = await axios.post(`${api.fileUpload}/v1.1/files/vehicle-image/${oemVehicleId}`, body);
      if (res.data.status === "error") {
        NotificationManager.error(res.data.msg);
      } else {
        // NotificationManager.success("Vehicle Image Upload Successful");
        getVehicle({spinner: false, vehicleID: oemVehicleId});
      }
      setPostLoading(false);
    } catch (err) {
      setPostLoading(false);
      // console.log(err);
      NotificationManager.error("Network error while uploading vehicle image, please try again or check your internet connection ");
    }
  };

  return {
    getVehicles,
    getVehiclesCount,
    createVehicle,
    updateVehicle,
    deleteVehicle,
    getVehicle,
    getWarrantyAndMileage,
    createWarrantyAndMileage,
    uploadVehicleImage,
  };
};
