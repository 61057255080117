import React, {useContext, useEffect, useState} from "react";
import {Table} from "reactstrap";
import {Card, CardImg, CardText, CardBody, CardLink, CardTitle, CardSubtitle} from "reactstrap";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText} from "reactstrap";
import DateTimePicker from "react-datetime-picker";
import "react-calendar/dist/Calendar.css";
import {useServiceCenterApiServices} from "../../../services/serviceCentersApiServices";
import {useQueryStringHelper} from "../../../helpers/queryStringHelper";
import {usePaginationHelper} from "../../../helpers/paginationHelper";
import Pagination from "react-js-pagination";
import {AuthContext} from "../../../contexts/AuthContext";
import {ServiceCentersContext} from "../../../contexts/ServiceCentersContext";
import EmptyPage from "../../../components/EmptyPage";
import {deleteDialog} from "../../../helpers/deleteDialogHelper";
import {RiEdit2Fill} from "react-icons/ri";
import {AiTwotoneDelete} from "react-icons/ai";
import {FaEye} from "react-icons/fa";
import {Link} from "react-router-dom";

export let closeServiceCenterModal;

const ServiceCenterTable = () => {
  const {auth} = useContext(AuthContext);
  const {creatServiceCenters, getServiceCenters, getServiceCentersCount, updateServiceCenters, deleteServiceCenters} = useServiceCenterApiServices();
  const {serviceCenters, serviceCentersCount} = useContext(ServiceCentersContext);
  const [serviceCenterModalOpen, setServiceCenterModalOpen] = useState(false);
  const [editedId, setEditedId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    address: "",
    repName: "",
    repPhoneNumber: "",
    repEmail: "",
    agreement: "",
    openingHour: "",
    closingHour: "",
    comment: "",
  });
  const {getPageNoFromQueryString} = useQueryStringHelper();
  const {paginate} = usePaginationHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());

  useEffect(() => {
    getServiceCenters({spinner: true, page: currentPage, oemId: auth?.auth_id});
    getServiceCentersCount({oemId: auth?.auth_id});
  }, []);

  const {name, email, phoneNumber, password, address, repName, repPhoneNumber, repEmail, agreement, openingHour, closingHour, comment} = formData;
  closeServiceCenterModal = () => {
    setServiceCenterModalOpen(false);
  };
  const openServiceCenterModal = (editedCenter) => {
    if (editedCenter) {
      setEditedId(editedCenter?.center_data?.auth_id);
      setFormData({
        name: editedCenter?.name,
        email: editedCenter?.email,
        phoneNumber: editedCenter?.phone_number,
        // password: editedCenter?.phone_number,
        address: editedCenter?.address,
        repName: editedCenter?.center_data?.rep_name,
        repPhoneNumber: editedCenter?.center_data?.rep_phone_number,
        repEmail: editedCenter?.center_data?.rep_email,
        // agreement:editedCenter?.center_data?.rep_name,
        openingHour: editedCenter?.center_data?.opening_hour,
        closingHour: editedCenter?.center_data?.closing_hour,
        comment: editedCenter?.center_data?.comment,
      });
    }
    setServiceCenterModalOpen(true);
  };

  const handleChange = (e) => {
    e.target.name !== "agreement" ? setFormData({...formData, [e.target.name]: e.target.value}) : setFormData({...formData, [e.target.name]: e.target.files[0]});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      name,
      email,
      phone_number: phoneNumber,
      password,
      address,
      rep_name: repName,
      rep_phone_number: repPhoneNumber,
      rep_email: repEmail,
      // agreement: "https://www.tatacapital.com/blog/wp-content/uploads/2020/01/SUV-cars-with-the-most-powerful-engines.jpg",
      opening_hour: openingHour,
      closing_hour: closingHour,
      comment,
    };
    !editedId ? creatServiceCenters({body, oemId: auth?.auth_id}) : updateServiceCenters({body, oemId: auth?.auth_id, centerId: editedId});
  };

  const onPaginate = (pageNumber) => {
    paginate({pageNumber, setCurrentPage, callbackFunction: getServiceCenters({spinner: false, page: pageNumber})});
  };

  const onDelete = (serviceCenter) => {
    const asyncFunction = () => deleteServiceCenters({centerId: serviceCenter?.center_data?.auth_id});
    deleteDialog({Message: "Delete Service Center", asyncFunction});
  };

  return (
    <div>
      <Card body>
        <CardTitle className="d-flex justify-content-between">Service centers</CardTitle>
        <div className="col-12">
          <div className="fw-bold d-flex justify-content-end">
            <Button className="btn-modal-accept me-2" onClick={openServiceCenterModal}>
              Add Service Center
            </Button>
          </div>
          {serviceCenters.length > 0 && (
            <Table borderless striped responsive className="text-nowrap">
              <thead>
                <tr>
                  <th className="fw-normal">Center name</th>
                  <th className="fw-normal">Center address</th>
                  <th className="fw-normal">Rep name</th>
                  <th className="fw-normal">Rep contact </th>
                </tr>
              </thead>
              <tbody>
                
                {serviceCenters.map((item) => (
                  <tr>
                    <td>{item?.name}</td>
                    <td>{item?.address}</td>
                    <td>{item?.center_data?.rep_name}</td>
                    <td>{item?.center_data?.rep_phone_number}</td>
                    <td>
                      <span className="text-warning fw-bold cursor-pointer" onClick={() => openServiceCenterModal(item)}>
                        <RiEdit2Fill />
                      </span>
                      <span className="text-danger fw-bold cursor-pointer mx-1" onClick={() => onDelete(item)}>
                        <AiTwotoneDelete />
                      </span>
                      <Link to={`/service-centers/${item?.center_data?.auth_id}`} className="text-decoration-none">
                        <span className="text-teal fw-bold cursor-pointer">
                          <FaEye />
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {serviceCenters.length === 0 && <EmptyPage text="No service center" />}

          <div>
            {serviceCenters.length > 0 && (
              <Pagination activePage={currentPage} itemsCountPerPage={20} totalItemsCount={serviceCentersCount} onChange={onPaginate} itemClass="page-item" linkClass="page-link" />
            )}
          </div>
        </div>
        <div>
          {/* service center modal */}

          {/* <div>
       
            <Modal isOpen={serviceCenterModalOpen} scrollable toggle={closeServiceCenterModal}>
              <ModalHeader toggle={closeServiceCenterModal}>Modal title</ModalHeader>
              <ModalBody>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={function noRefCheck() {}}>
                  Do Something
                </Button>{" "}
                <Button onClick={function noRefCheck() {}}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </div> */}
          <Modal isOpen={serviceCenterModalOpen} toggle={closeServiceCenterModal} scrollable backdrop="static">
            <ModalHeader toggle={closeServiceCenterModal}>
              <div className="d-flex justify-content-between">
                <div>{editedId ? "Update" : "Add"} Service Center</div>
              </div>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div className="mt-2 w-100">
                  <div className="d-flex justify-content-between mt-3">
                    <FormGroup className="w-50 me-2">
                      <Label for="name">Service center name</Label>
                      <Input id="name" name="name" type="text" onChange={handleChange} value={name} />
                    </FormGroup>
                    <FormGroup className="w-50 ms-2">
                      <Label for="email">Service center email</Label>
                      <Input id="email" name="email" type="email" onChange={handleChange} value={email} />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <FormGroup className="w-50 me-2">
                      <Label for="phoneNumber">Phone number</Label>
                      <Input id="phoneNumber" name="phoneNumber" type="tel" onChange={handleChange} value={phoneNumber} />
                    </FormGroup>
                    <FormGroup className="w-50 ms-2">
                      <Label for="address">Address</Label>
                      <Input id="address" name="address" type="text" onChange={handleChange} value={address} />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <FormGroup className="w-50 me-2">
                      <Label for="rep-name">Rep name</Label>
                      <Input id="rep-name" name="repName" type="text" onChange={handleChange} value={repName} />
                    </FormGroup>
                    <FormGroup className="w-50 ms-2">
                      <Label for="rep-email">Rep email</Label>
                      <Input id="rep-email" name="repEmail" type="email" onChange={handleChange} value={repEmail} />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <FormGroup className="w-50 me-2">
                      <Label for="repPhoneNo">Rep Phone Number</Label>
                      <Input id="repPhoneNo" name="repPhoneNumber" type="tel" onChange={handleChange} value={repPhoneNumber} />
                    </FormGroup>
                    <FormGroup className="w-50 ms-2">
                      <Label for="comment">Comment</Label>
                      <Input id="comment" name="comment" type="textarea" onChange={handleChange} value={comment} />
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    {/* <FormGroup className="w-50 me-2">
                      <Label for="agreement">Agreement</Label>
                      <Input id="agreement" name="agreement" type="file" onChange={handleChange} value={agreement} />
                    </FormGroup> */}
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <FormGroup className="w-50 me-2">
                      <Label for="opening-hour">Opening hour</Label>
                      <Input id="opening-hour" name="openingHour" type="time" onChange={handleChange} value={openingHour} />
                    </FormGroup>
                    <FormGroup className="w-50 ms-2">
                      <Label for="closing-hour">Closing hour</Label>
                      <Input id="closing-hour" name="closingHour" type="time" onChange={handleChange} value={closingHour} />
                    </FormGroup>
                  </div>
                </div>
                <div className="mt-3 text-center">
                  <Button className="btn-modal-accept" type="submit">
                    Submit
                  </Button>
                  <Button className="btn-modal-cancel ms-2" onClick={closeServiceCenterModal}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </Card>
    </div>
  );
};
export default ServiceCenterTable;
