export const serviceRequestType = [
  { name: "Maintenance", value: "maintenance" },
  { name: "Repair", value: "repair" },
  { name: "Servicing", value: "servicing" },
  { name: "Defect", value: "defect" },
];
export const serviceRequestStatuses = [
  { name: "Pending", value: "pending" },
  { name: "Accepted", value: "accepted" },
  { name: "Rejected", value: "rejected" },
  { name: "Awaiting Approval", value: "awaiting-approval" },
  { name: "Approved", value: "approved" },
  { name: "Repair Time Set", value: "repair_time_set" },
  { name: "Ongoing", value: "ongoing" },
  { name: "Completed", value: "completed" },
];
