import React, {useState} from "react";
import {Table} from "reactstrap";
import {Card, CardImg, CardText, CardBody, CardLink, CardTitle, CardSubtitle} from "reactstrap";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button, FormText} from "reactstrap";
import DateTimePicker from "react-datetime-picker";
import "react-calendar/dist/Calendar.css";

const WorkOrderTable = ({justView}) => {
  const [items, setItems] = useState(Array.from({length: 6}));
  const [requestDetailModalOpen, setRequestDetailModalOpen] = useState(false);
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [rejectionReasonModal, setRejectionReasonModal] = useState(false);
  const [value, onChange] = useState(new Date());

  const data = [
      {defectReport: "Maintenance", driverName: "Cameron Willimson", plateNo: "DHS4234BC", urgency: "High", status: "Pending"},
      {defectReport: "Maintenance", driverName: "Cameron Willimson", plateNo: "DHS4234BC", urgency: "Low", status: "Accepted"},
      {defectReport: "Maintenance", driverName: "Cameron Willimson", plateNo: "DHS4234BC", urgency: "Low", status: "Ongoing"},
      {defectReport: "Maintenance", driverName: "Cameron Willimson", plateNo: "DHS4234BC", urgency: "Low", status: "Completed"},
      {defectReport: "Maintenance", driverName: "Cameron Willimson", plateNo: "DHS4234BC", urgency: "Low", status: "Rejected"},
];

  const closeRequestDetailModal = () => {
    setRequestDetailModalOpen(false);
  };

  const openRequestDetailModal = () => {
    setRequestDetailModalOpen(true);
  };

  const closeAvailabilityModal = () => {
    setAvailabilityModal(false);
  };
  const openAvailabilityModal = () => {
    setAvailabilityModal(true);
  };

  const closeRejectionReasonModal = () => {
    setRejectionReasonModal(false);
  };
  const openRejectionReasonModal = () => {
    setRejectionReasonModal(true);
  };

  const acceptRequest = () => {
    closeRequestDetailModal();
    openAvailabilityModal();
  };
  const rejectRequest = () => {
    closeRequestDetailModal();
    openRejectionReasonModal();
  };


  return (
    <div>
      <Card body>
        <CardTitle className="d-flex justify-content-between">Service requests</CardTitle>
        <div className="col-12">
          <Table borderless striped responsive className="text-nowrap">
            <thead>
              <tr>
                <th className="fw-normal">Defect reports</th>
                <th className="fw-normal">Driver name</th>
                <th className="fw-normal">Plate number</th>
                <th className="fw-normal">Urgency</th>
                <th className="fw-normal">Status </th>
                <th className="fw-normal">Action </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr>
                  <td>{item.defectReport}</td>
                  <td>{item.driverName}</td>
                  <td>{item.plateNo}</td>
                  <td>
                    <span className="text-danger fw-bold">{item.urgency}</span>
                  </td>
                  <td>
                    <span class="badge bg-secondary">{item.status}</span>
                  </td>
                  <td onClick={openRequestDetailModal}>{justView ? <span className="text-teal fw-bold">View</span> : <button className="btn btn-sm btn-success p-0 px-3">Assign</button>}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div>
          {/* service request details */}
          <Modal isOpen={requestDetailModalOpen} toggle={closeRequestDetailModal} size="md" scrollable>
            <ModalHeader toggle={closeRequestDetailModal}>
              <div className="d-flex justify-content-between">
                <div>View Service Request</div>
                <div>
                  <small className="px-3 py-1 rounded " style={{backgroundColor: "#FCF4E8", color: "#E5870D"}}>
                    Pending
                  </small>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex justify-content-between mt-2 ">
                <div className="w-50">
                  <small>Request Type</small>
                  <div>Maintenance</div>
                </div>
                <div className="w-50">
                  <small>Service center assigned to</small>
                  <div> Ammen millit molit non desuruno</div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div className="w-50">
                  <small>Driver name</small>
                  <div>Cameron Williamson</div>
                </div>
                <div className="w-50">
                  <small>Urgency</small>
                  <div className="text-danger">High</div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div className="w-50">
                  <small>Vehice Model</small>
                  <div>Lamborgini Horacan 2020</div>
                </div>
                <div className="w-50">
                  <small>Time of request</small>
                  <div>21st May, 2021</div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <div className="w-50">
                  <small>Plate number</small>
                  <div>DHS2354BC</div>
                </div>
                <div className="w-50">
                  <small>Image where applicable</small>
                  <div style={{color: "#19AF7C"}}>
                    <u>view image</u>
                  </div>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              {!justView && (
                <>
                  <Button className="btn-modal-accept" onClick={acceptRequest}>
                    Accept
                  </Button>
                  <Button className="btn-modal-cancel" onClick={rejectRequest}>
                    Reject
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>

          {/* availability date and time modal */}
          <Modal isOpen={availabilityModal} toggle={closeAvailabilityModal} size="md" scrollable>
            <ModalHeader toggle={closeAvailabilityModal}>
              <div className="d-flex justify-content-between">
                <div>Set availability</div>
              </div>
            </ModalHeader>
            <ModalBody style={{height: 300}}>
              <div className="d-flex justify-content-between mt-2 text-center">
                <DateTimePicker onChange={onChange} value={value} />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-modal-accept" onClick={function noRefCheck() {}}>
                Submit
              </Button>
              <Button className="btn-modal-cancel" onClick={function noRefCheck() {}}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          {/*rejection reason modal */}
          <Modal isOpen={rejectionReasonModal} toggle={closeRejectionReasonModal} size="sm" scrollable>
            <ModalHeader toggle={closeRejectionReasonModal}>
              <div className="d-flex justify-content-between">
                <div>Reject service request</div>
              </div>
            </ModalHeader>
            <ModalBody style={{height: 300}}>
              <div className="mt-2 w-100">
                <Form>
                  <FormGroup>
                    <Label for="exampleText">Reason</Label>
                    <Input type="textarea" name="text" id="exampleText" rows="9" placeholder="max xx words" />
                  </FormGroup>
                </Form>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-modal-accept" onClick={function noRefCheck() {}}>
                Submit
              </Button>
              <Button className="btn-modal-cancel" onClick={function noRefCheck() {}}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Card>
    </div>
  );
};
export default WorkOrderTable;
