import React, {useContext, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {AuthContext} from "../contexts/AuthContext";
import {LoadingContext} from "../contexts/LoadingContext";
import LoaderPage from "../components/LoaderPage";
import LoadingBar from "react-top-loading-bar";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

const AuthMiddleware = ({component: Component, layout: Layout, isAuthProtected, pageTitle, leadText, ...rest}) => {
  const {setGetLoading, getLoading, postLoading} = useContext(LoadingContext);
  const ref = useRef(null);
  useEffect(() => {
    postLoading ? ref.current.continuousStart() : ref.current.complete();
  }, [postLoading]);

  const access_token = localStorage.getItem("access_token");

  if (isAuthProtected && !access_token) {
    return (
      <>
        <LoadingBar color="#f11946" ref={ref} />
        <Navigate to="/signin" />;
      </>
    );
  }
  if (!isAuthProtected && access_token) {
    return (
      <>
        <LoadingBar color="#f11946" ref={ref} />
        <Navigate to="/" />;
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>{"LagosRide OEM - " + pageTitle}</title>
      </Helmet>
      <Layout title={leadText}>
        {/* for loading when theres a post request */}
        <LoadingBar color="#f11946" ref={ref} />
        {/* for loading when a page mounts */}
        {getLoading && <LoaderPage size={100} type={"ThreeDots"} />}
        <Component
        //  {...props}
        />
      </Layout>
    </>
  );
};

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};
export default AuthMiddleware;
