import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {AuthContext} from "../contexts/AuthContext";
import {Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, FormText} from "reactstrap";
import {NotificationManager} from "react-notifications";
import {useAuthApiServices} from "../services/AuthApiServices";
import {LoadingContext} from "../contexts/LoadingContext";
import {MdArrowBackIosNew, MdNotificationsNone} from "react-icons/md";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
export let closePasswordChangeModal;

const PagesTop = ({leadText}) => {
  const {changePassword} = useAuthApiServices();
  const {postLoading} = useContext(LoadingContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {auth} = useContext(AuthContext);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const [formData, setFormData] = useState({oldPasword: "", password: "", confirmPassword: ""});
  const {oldPasword, password, confirmPassword} = formData;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  closePasswordChangeModal = () => {
    setPasswordChangeModalOpen(false);
    setFormData({oldPasword: "", password: "", confirmPassword: ""});
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = (e) => {
    const {oldPasword, password, confirmPassword} = formData;
    e.preventDefault();
    if (password !== confirmPassword) {
      NotificationManager.error("Passwords must match");
    } else {
      const body = {old_password: oldPasword, new_password: password};
      // console.log(oldPasword, password, confirmPassword);
      changePassword({body});
    }
  };

  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-between">
      <div className="fs-4 fw-bold">
        <span className="me-2" onClick={() => navigate(-1)}>
          <MdArrowBackIosNew />
        </span>
        {leadText}
      </div>
      <div className="d-flex ">
        {/* <div className="position-relative">
          <MdNotificationsNone className="me-2" size={32} color="red" />
          <small className="blink text-white position-absolute fw-bold rounded-circle" style={{top: 4, left: 7, fontSize: 6, padding: "0 4px", backgroundColor: "red"}}>
            <span>.</span>
          </small>
        </div> */}
        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
          <DropdownToggle caret className="btn-modal-accept">
            {auth?.name.length > 20 ? auth?.name.slice(0, 20) + "..." : auth?.name}
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem header>Header</DropdownItem>
            <DropdownItem disabled>Action</DropdownItem> */}
            <DropdownItem className="" onClick={() => setPasswordChangeModalOpen(true)}>
              Change Password
            </DropdownItem>
            {/* <DropdownItem divider />
            <DropdownItem>Another Action</DropdownItem> */}
          </DropdownMenu>
        </Dropdown>
      </div>

      {/* change password modal */}
      <Modal isOpen={passwordChangeModalOpen} toggle={closePasswordChangeModal} size="sm" scrollable backdrop="static">
        <ModalHeader toggle={closePasswordChangeModal}>
          <div className="d-flex justify-content-between">
            <div>Change Password</div>
          </div>
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody style={{height: 300}}>
            <div className="mt-2 w-100">
              <FormControl sx={{width: "100%"}} variant="filled">
                <InputLabel htmlFor="filled-adornment-password" color="success">
                  Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={showOldPassword ? "text" : "password"}
                  placeholder="Enter Old Password"
                  name="oldPasword"
                  value={oldPasword}
                  color="success"
                  onChange={handleChange}
                  required
                  endAdornment={
                    <InputAdornment position="end" onClick={() => setShowOldPassword(!showOldPassword)}>
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="mt-2 w-100">
              <FormControl sx={{width: "100%"}} variant="filled">
                <InputLabel htmlFor="filled-adornment-password" color="success">
                  New Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter New Password"
                  value={password}
                  name="password"
                  color="success"
                  onChange={handleChange}
                  required
                  endAdornment={
                    <InputAdornment position="end" onClick={() => setShowNewPassword(!showNewPassword)}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="mt-2 w-100">
              <FormControl sx={{width: "100%"}} variant="filled">
                <InputLabel htmlFor="filled-adornment-password" color="success">
                  Confirm New Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={showNewPassword2 ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  name="confirmPassword"
                  color="success"
                  onChange={handleChange}
                  required
                  endAdornment={
                    <InputAdornment position="end" onClick={() => setShowNewPassword2(!showNewPassword2)}>
                      {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <div className="mt-4">
              <Button className="btn-modal-accept" type="submit">
                Submit
              </Button>
              <Button className="btn-modal-cancel ms-3" onClick={closePasswordChangeModal} disabled={postLoading}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  );
};

export default PagesTop;
