import {useContext} from "react";
import axios from "axios";
import {LoadingContext} from "../contexts/LoadingContext";
import {api} from "../constants/urls";
import {NotificationManager} from "react-notifications";

export const useMessagingService = () => {
  const {setGetLoading, setPostLoading} = useContext(LoadingContext);

  const sendMessage =
    ({body}) =>
    async (dispatch) => {
      try {
        setPostLoading(true);
        const res = await axios.post(`${api.messaging}/v1.1/messages/send`, body);
        if (res.data.status === "error") {
          NotificationManager.error(res.data.msg);
        } else {
          await NotificationManager.success(" Message sent!");
        }
        setPostLoading(false);
      } catch (e) {
        console.log(e);
        setPostLoading(false);
      }
    };

  return {
    sendMessage,
  };
};
