import io from "socket.io-client";

import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {NotificationsContext} from "../contexts/NotificationsContext";

// console.log(path)

export const useSocketAction = () => {
  const {notificationAlert, setNotificationAlert} = useContext(NotificationsContext);
  const {auth} = useContext(AuthContext);
  const environment = auth?.data_mode;

  let url;
  let path;
  url = environment === "live" ? "https://oem-service-microservices.api.lagosride.com" : "https://staging-server.lagosride.com";
  path = environment === "live" ? null : "/oem-service";
  const token = localStorage.getItem("access_token");

  const appSocket = {};
  appSocket.createConnection = () => {
    if (appSocket.io && appSocket.io.connected) return;
    appSocket.io = io.connect(`${url}?user_type=${auth?.user_type}&token=${token}`, {path, transports: ["websocket"]});

    appSocket.io.on("connect", () => {
      console.log("Connected");
    });
    appSocket.io.on("connect_error", (err) => {
      // console.log("Connect error" + err);
      // console.log(JSON.stringify(err));
    });
    appSocket.io.on("error", (err) => {
      console.log("Error" + err);
    });
    appSocket.io.on("disconnect", (err) => {
      console.log("Disconnect" + err);
    });

    appSocket.io.on("msg", (data) => {
      console.log(data);
      setNotificationAlert(data);
    });
  };

  appSocket.disconnect = () => {
    appSocket.io.disconnect();
  };
  return {
    appSocket,
  };
};
