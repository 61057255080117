import React, {createContext, useState} from "react";

export const VehicleContext = createContext();

const VehicleContextProvider = ({children}) => {
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesCount, setVehiclesCount] = useState(0);
  const [vehicle, setVehicle] = useState({});
  const [warrantyAndMillage, setWarrantyAndMillage] = useState({});

  return (
    <VehicleContext.Provider value={{vehicles, setVehicles, vehiclesCount, setVehiclesCount, vehicle, setVehicle, warrantyAndMillage, setWarrantyAndMillage}}>{children} </VehicleContext.Provider>
  );
};

export default VehicleContextProvider;
